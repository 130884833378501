import { useCallback, useEffect } from "react";
import { useBlocker, BlockerFunction } from "react-router-dom";
import React from "react";
import ConfirmDialog from "../dialog/ConfirmDialog";

const originalBeforeUnload = window.onbeforeunload;

export const NavigationBlocker = ({ when }: { when: boolean }) => {
    useEffect(() => {
        if (when) {
            window.onbeforeunload = function () {
                return "";
            };
        }
        return () => {
            window.onbeforeunload = originalBeforeUnload;
        };
    }, [when]);

    const shouldBlock = useCallback<BlockerFunction>(
        ({ currentLocation, nextLocation }) => {
            return when && currentLocation.pathname !== nextLocation.pathname;
        },
        [when],
    );

    const blocker = useBlocker(shouldBlock);

    const onExit = () => {
        if (blocker.proceed) {
            blocker.proceed();
        }
    }

    const onCancel = () => {
        if (blocker.reset) {
            blocker.reset();
        }
    }

    return (
        <ConfirmDialog
            open={blocker.state === "blocked"}
            disableEscapeKeyDown
            disableBackdropClick
            onOk={onExit}
            onCancel={onCancel}
            title="Confirm exit"
            content="Discard all changes?"
            cancelLabel="Cancel"
            okLabel="Discard and exit" />
    );
};