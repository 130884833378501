import * as React from 'react';
import { DialogContent, DialogActions } from '@mui/material';
import { ReactNode } from 'react';
import withForm, { ValidateProps } from '../../validation/ValidatedForm';
import { XSMobileDialog } from '../dialog/MobileDialog';
import DialogAppBar from '../dialog/DialogAppBar';
import { processEnterKey } from '../../util/react_utils';
import AppButton from '../../common/components/AppButton';
import ConfirmDialog from "../dialog/ConfirmDialog";
import { NavigationBlocker } from "../components/NavigationBlocker";

export interface WithSave<T> {
    save: (val: T) => void;
    savePromise?: (val: T) => Promise<boolean>;
}

export interface WithEditDialogProps {
    open: boolean;
    close: () => void;
    fullScreen?: boolean;
    skipEnterKey?: boolean;
}

export interface CommonEditDialogProps<T> {
    value?: T;
    maxValue?: T;
    minValue?: T;
    label: string;
    hint?: string;
    icon?: ReactNode;
    contentPadding?: string;
}

export interface EditDialogProps<T> {
    setValue: (val?: T) => void;
}

type Props<P, T> = P & EditDialogProps<T> & CommonEditDialogProps<T>;
type State<T> = {
    value?: T;
    changed?: boolean;
    confirmExit?: boolean;
};

const withEditDialog = function <P, T>(Component: React.ComponentType<Props<P, T>>) {
    type ResProps = P & WithSave<T> & WithEditDialogProps & CommonEditDialogProps<T> & ValidateProps;

    return withForm(class extends React.Component<ResProps, State<T>> {
        state: State<T>;

        constructor(props: ResProps) {
            super(props);
            this.state = {};
        }

        private handleSetValue = (val: T) => {
            const { value: stateValue } = this.state;
            if ((stateValue as string)?.length != (val as string)?.length) {
                this.setState({ value: val, changed: !!stateValue });
            }
        }

        private handleSave = () => {
            if (this.props.valid && !this.props.valid()) {
                return;
            }
            this.props.save(this.state.value!);
        }

        private handleClose = () => {
            const { close: closeDialog } = this.props as any;
            if (!this.state.changed) {
                closeDialog(this.state.value)
            } else {
                this.setState({ confirmExit: true });
            }
        }

        componentDidUpdate(prevProps: Readonly<ResProps>, prevState: Readonly<State<T>>) {
            if (this.props.value !== prevProps.value) {
                this.setState({ value: this.props.value });
            }
        }

        render() {
            const { contentPadding, label, hint, save, open, fullScreen, skipEnterKey, value, icon, valid, close: closeDialog, ...others } = this.props as any;
            const { value: stateValue, confirmExit } = this.state;
            const padding = contentPadding ? { padding: contentPadding } : {};
            return (
                <XSMobileDialog open={open} maxWidth={'xs'} fullWidth={true} fullScreen={fullScreen} onClose={this.handleClose}>
                    <NavigationBlocker when={!!this.state.changed} />
                    <DialogAppBar label={label} close={this.handleClose} />
                    <DialogContent style={{ ...padding }} onKeyDown={event => processEnterKey(event, this.handleSave, skipEnterKey)}>
                        <Component label={label} hint={hint} icon={icon} {...others} value={stateValue || value} setValue={this.handleSetValue} />
                    </DialogContent>
                    <DialogActions>
                        <AppButton onClick={this.handleClose} color="info">Cancel</AppButton>
                        <AppButton onClick={this.handleSave} color="secondary">Save</AppButton>
                    </DialogActions>
                    {!!confirmExit && <ConfirmDialog
                        open
                        disableEscapeKeyDown
                        disableBackdropClick
                        onOk={() => {
                            this.setState({ value: this.props.value, confirmExit: undefined }, closeDialog(this.props.value))
                        }}
                        onCancel={() => this.setState({ confirmExit: undefined })}
                        title="Confirm exit"
                        content="Discard all changes?"
                        cancelLabel="Cancel"
                        okLabel="Discard and exit" />}
                </XSMobileDialog>
            );
        }
    });
};

export default withEditDialog;
