import * as React from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { PersonIcon, AddEditIcon, GolfersIcon, LinkIcon, ScoringWithBadgeIcon, SettingsIcon, ToolsIcon, StarIcon } from '../../common/Icons';
import { Event } from '../../types/EventTypes';
import { useUserAware } from '../../auth/Auth';
import { drawerWidth2, useAppStyles } from '../../styles';
import AppButton from 'src/common/components/AppButton';
import { Flex, FlexCenter, IconLinkElement, Spacing } from 'src/common/Misc';
import { AppColors } from 'src/main/Theme';
import AppDrawer, { AppDrawerItem } from 'src/common/components/AppDrawer';
import { AccountActions, accountAction } from 'src/auth/StripeRedirect';
import { GolferStats } from './EventInfo';
import { Urls } from 'src/util/config';
import CopyTextField from 'src/common/components/CopyTextField';
import { Typography } from '@mui/material';
import ProPlanDialog from 'src/auth/ProPlanDialog';
import ProBadge from 'src/common/ProBadge';

export function TemplateDrawer({ tabs, width, mobileOnly }: { tabs: Array<AppDrawerItem>, width: number, mobileOnly?: boolean }) {
    const [proBanner, showProBanner] = React.useState(false);
    const userAware = useUserAware();
    if (!userAware.hasPro && !tabs.find(tab => tab.id === 'getpro')) {
        tabs.push({
            id: '',
            element: <Spacing />
        });
        tabs.push({
            id: 'getpro',
            element: (
                <AppButton
                    sx={{ width: '100%', backgroundColor: AppColors.webGreen500 }}
                    onClick={() => showProBanner(true)}>
                    <FlexCenter><StarIcon sx={{ width: '16px', paddingRight: '4px' }} />Get Pro</FlexCenter>
                </AppButton>
            )
        });
    }
    const handleCloseProPlan = (name?: AccountActions) => {
        showProBanner(false);
        accountAction(userAware, name);
    }
    return <>
        <AppDrawer tabs={tabs} width={width} mobileOnly={mobileOnly} />
        {proBanner &&
            <ProPlanDialog handleClose={handleCloseProPlan} />}
    </>;
}

export function EventDrawer({ event, golferStats }: { event: Event, golferStats: GolferStats }) {
    const classes = useAppStyles();
    const userAware = useUserAware();
    const tabs: Array<AppDrawerItem> = [
        {
            id: 'all',
            name: 'All events',
            url: '/events',
            icon: <ChevronLeftIcon />
        }, {
            id: event.id,
            name: event.name,
            opened: true,

        }, {
            id: 'settings',
            name: 'Settings',
            opened: true,
            url: `/events/${event.id}/settings`,
            icon: <SettingsIcon />,
            children: [{
                id: 'general',
                name: 'General',
                url: '/general'
            }, {
                id: 'portal',
                name: 'Event site',
                url: '/portal'
            }, {
                id: 'sponsorship',
                name: <>Sponsorship {!userAware.hasPro && <ProBadge small ml={4} />}</>,
                url: '/sponsorship'
            }]
        }, {
            id: 'golfers',
            name: 'Golfers',
            url: `/events/${event.id}/golfers`,
            icon: <GolfersIcon />,
            opened: true,
            children: [{
                id: 'list',
                name: 'List',
                url: '/list',
                secondary: golferStats.golfersSubLabel,
                marked: !golferStats.golfersAvailable
            }, {
                id: 'teams',
                name: 'Teams',
                url: '/teams',
                secondary: golferStats.teamsSubLabel,
                marked: !golferStats.allTeamsPaired
            }, {
                id: 'schedule',
                name: 'Schedule',
                url: '/schedule',
                secondary: golferStats.groupsSubLabel,
                marked: !golferStats.allScheduled || golferStats.groupsCount == 0
            }, {
                id: 'invites',
                name: 'Invites',
                url: '/invites',
                secondary: golferStats.invitesSubLabel
            }]
        }, {
            id: 'scoring',
            name: 'Scoring',
            url: `/events/${event.id}/scores`,
            icon: <ScoringWithBadgeIcon />
        }, {
            id: 'tools',
            name: 'Tools',
            url: `/events/${event.id}/tools`,
            icon: <ToolsIcon />,
            opened: true,
            children: [{
                id: 'print',
                name: <>Print {!userAware.hasPro && <ProBadge small ml={4} />}</>,
                url: '/print'
            }, {
                id: 'changelog',
                name: 'Changelog',
                url: '/changelog'
            }]
        }, {
            id: '',
            divider: true
        }, {
            id: '',
            element: <Spacing />
        }, {
            id: '',
            name: 'Event site',
        }, {
            id: 'editinfo',
            name: 'Add/edit info',
            url: `/events/${event.id}/settings/portal`,
            icon: <AddEditIcon />,
            unselectable: true
        }, {
            id: '',
            element: (
                <Flex>
                    <CopyTextField
                        btnOnly
                        readOnly
                        color="secondary"
                        fieldValue={Urls.makeAboutLink(event.publicId)}
                        btnStyle={{ minWidth: 124 }}
                        btnLabel={<><LinkIcon sx={{ width: '16px', paddingRight: '4px' }} /> Copy link</>}
                    />
                    <span style={{ width: 8 }} />
                    <AppButton
                        color="secondary"
                        style={{ minWidth: 64 }}
                        onClick={() => window.open(`${window.location.origin}/event/${event.publicId}`, '_blank')}
                    >
                        Open <ArrowOutwardIcon sx={{ width: '16px', paddingLeft: '4px' }} />
                    </AppButton>
                </Flex>
            )
        }, {
            id: ``,
            element: (
                <Typography style={{ paddingBottom: 8, marginTop: 12 }} className={classes.link} noWrap>
                    <IconLinkElement href={`/tv/${event.publicId}`} target="tv">
                        View TV Standings
                    </IconLinkElement>
                </Typography>
            ),
        }, {
            id: '',
            element: <Spacing />
        }, {
            id: '',
            divider: true
        }, {
            id: 'account',
            name: 'Account',
            url: '/account/settings',
            icon: <PersonIcon />,
        }
    ];
    return (
        <TemplateDrawer tabs={tabs} width={drawerWidth2} />
    );
}
