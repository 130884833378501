import * as React from 'react';
import { ChangeEvent, ReactNode, KeyboardEvent, CSSProperties } from 'react';
import { Typography, DialogContent, FormControlLabel, Checkbox, DialogActions, IconButton, MenuItem, MenuList, Grow, Paper, Popper } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { Theme } from '@mui/material/styles';
import { WithStyles, StyleRules } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import DropDownArrowIcon from '@mui/icons-material/ArrowDropDown';
import EditIcon from '@mui/icons-material/Edit';
import { InfoIcon } from '../../../common/Icons';
import { SMMobileDialog } from '../../../common/dialog/MobileDialog';
import DialogAppBar from '../../../common/dialog/DialogAppBar';
import AppButton from '../../../common/components/AppButton';
import ConfirmDialog from '../../../common/dialog/ConfirmDialog';
import { formatHandicap, fullName, golfersOfTeam, shortName, getSameNameGolfersIds } from '../../../contact/Contact';
import {
    EventBase, Competition, Contact, ContactDetails, Team, Tee, Score, ReportedScore, HolesType, HOLES_9, HOLES_9_9, teeTimeName, isNetMode, isGrossMode, isTeamScoringExceptBB,
    isTeamScoring, getTee, isStablefordScoringOrMode, getHoleLabel, ScoringFormatIndividual, ScoringFormatSkins, ScoringFormatTeams,
    ContactScoringState, isSkinsScoring, skinsWithCarryOvers, getHolesRange, Distance, EventData,
    Round
} from '../../../types/EventTypes';
import { teeName, deleteGolfersFromEvent, elog, saveContact, SkinsScoringState, evalWonHolesNumAroundIndex, getActualSkin, updateGolferDisStatus } from '../../Event';
import { range } from '../../../util/utility';
import { getContrastTextColor, COLOR_NAMES } from '../../../util/colors';
import * as Scoring from '../../../scoring/scoring';
import { FirebaseDocComponent } from '../../../common/WithData';
import { getGolferPlayingHandicap, getGolferRangeHandicap, getHoleHandicap, hardestTee, getHandicapsAllowance } from '../../../scoring/handicap';
import * as Backend from '../../../util/firebase';
import { styles, editscoreCellSize, editscoreLabelWidth, editscoreTitleWidth, editscoreSubLabelWidth, gap, useAppStyles } from '../../../styles';
import { showAlert, showProgress, InfoElement } from 'src/redux/ReduxConfig';
import EditContactDialog from '../../../contact/EditContactDialog';
import { errMsg } from '../../../util/firebase';
import { showReportedGolfersDeletionAlert } from '../../../util/common_alerts';
import { AppColors } from '../../../main/Theme';
import { STABLEFORD_MAX_POINT, STABLEFORD_MIN_POINT } from './StablefordPointSettingsDialog';
import { processEnterKey } from 'src/util/react_utils';
import { EmailVariant, sendPaymentMessage } from "../../../util/email_utils";
import { withProgress } from 'src/util/ProgressPromise';
import { WithPossibleUserAware, useUserAware } from 'src/auth/Auth';
import { NavigationBlocker } from "../../../common/components/NavigationBlocker";

export const KEY_BACKSPACE = 'Backspace'; // 8;
export const KEY_ARROWLEFT = 'ArrowLeft'; // 37;
export const KEY_ARROWUP = 'ArrowUp'; // 38;
export const KEY_ARROWRIGHT = 'ArrowRight'; // 39;
export const KEY_ARROWDOWN = 'ArrowDown'; // 40;

const specialSymbol = ' ';
const pickUpImage = '/img/PickUp24.svg';
const linkIconImage = '/img/link_icon_transparent14x14.png';

function displayScore(value: any) {
    if (typeof value === 'number') {
        return value;
    }
    return '';
}

function validateScores(gross: Array<number>) {
    for (let i = 0; i < gross.length; i++) {
        if (!gross[i]) {
            gross[i] = 0;
        }
    }
}

export function scoresOf(id: string, eventOrRound: EventBase, contactId: string, competition: Competition, contacts: Array<Contact>, scores: Map<string, Score>): Score {
    const scoring = competition.scoring;
    if (id === contactId && scoring.format === ScoringFormatTeams.best_ball) {
        const tees = contacts.map(c => getTee(eventOrRound, competition, c.gender, c));
        return Scoring.bestBallGross(contacts.map(i => i.id), scores, tees, eventOrRound.holesType);
    } else {
        return scores.get(id) || { id: id } as Score;
    }
}

export function reportedScoresOf(id: string, eventOrRound: EventBase, contactId: string, competition: Competition, contacts: Array<Contact>, reportedScores: Map<string, ReportedScore>): ReportedScore {
    const scoring = competition.scoring;
    if (id === contactId && scoring.format === ScoringFormatTeams.best_ball) {
        const tees = contacts.map(c => getTee(eventOrRound, competition, c.gender, c));
        return Scoring.bestBallReported(contacts.map(i => i.id), reportedScores, tees, eventOrRound.holesType);
    } else {
        return reportedScores.get(id) || { id: id } as ReportedScore;
    }
}

export function getHoleScore(hole: number, score?: Score, reportedScore?: ReportedScore) {
    const gross = score?.gross;
    const strokes = reportedScore?.strokes;
    const watched = reportedScore?.watched;
    let holeScore = 0;
    if (gross && gross[hole]) {
        holeScore = strokes && watched && strokes[hole] && strokes[hole] !== watched[hole] && watched[hole] === gross[hole] ? strokes[hole] : gross[hole];
    } else {
        holeScore = (strokes && strokes[hole]) || 0;
    }
    return holeScore;
}

export function finalScoresOf(id: string, event: EventBase, contactId: string, competition: Competition, contacts: Array<Contact>, scores: Map<string, Score>, reportedScores: Map<string, ReportedScore>): Array<number> {
    const score = scoresOf(id, event, contactId, competition, contacts, scores);
    const reportedScore = reportedScoresOf(id, event, contactId, competition, contacts, reportedScores);
    const holeScores: Array<number> = [];
    for (let hole = 0; hole < 18; hole++) {
        holeScores.push(getHoleScore(hole, score, reportedScore));
    }
    return holeScores;
}

export function finalScoresOf2(id: string, event: EventBase, contactId: string, competition: Competition, contacts: Array<Contact>, scores: Map<string, Score>, reportedScores: Map<string, ReportedScore>): Array<number> {
    const score = scoresOf(id, event, contactId, competition, contacts, scores);
    const reportedScore = reportedScoresOf(id, event, contactId, competition, contacts, reportedScores);
    const holeScores: Array<number> = [];
    if (score && score.gross) {
        for (let hole = 0; hole < score.gross.length; hole++) {
            if (!score.gross[hole]) {
                holeScores.push(!!reportedScore.strokes ? reportedScore.strokes[hole] : 0);
            } else {
                holeScores.push(!!reportedScore.strokes && !!reportedScore.watched && !!reportedScore.strokes[hole] && reportedScore.strokes[hole] !== reportedScore.watched[hole] && reportedScore.watched[hole] === score.gross[hole]
                    ? reportedScore.strokes[hole] : score.gross[hole]);
            }
        }
    }
    return holeScores;
}

export function Padding() {
    return (
        <span>&nbsp;&nbsp;&nbsp;</span>
    );
}

const rowStyles = (theme: Theme) => {
    return {
        row: {
            display: 'flex',
            alignItems: 'center',
            borderBottom: `1px solid ${theme.palette.grey[50]}`,
            color: 'white'
        },
        cellsRoot: {
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: 3,
            paddingBottom: 3
        },
        cells: {
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingLeft: 0,
            paddingRight: 0,
            borderRadius: 20
        },
        cell: {
            position: 'relative',
            width: editscoreCellSize,
            height: editscoreCellSize,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        cellsInOut: {
            position: 'relative',
            width: editscoreCellSize,
            height: editscoreCellSize,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        flex: {
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            height: editscoreCellSize
        },
        label: {
            paddingLeft: theme.spacing(1),
            width: editscoreLabelWidth - (gap(theme, 3)),
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'inline-block',
            fontWeight: 'inherit'
        },
        titleLabel: {
            paddingLeft: theme.spacing(1),
            width: editscoreTitleWidth,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'inline-block',
            fontWeight: 'inherit'
        },
        subLabel: {
            paddingLeft: theme.spacing(1),
            width: editscoreSubLabelWidth - gap(theme, 2),
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'inline-block',
            fontWeight: 'inherit',
            fontSize: '0.65rem',
            marginBottom: -2
        },
        gross: {
            color: theme.palette.getContrastText(theme.palette.grey[200])
        },
    } as StyleRules;
};

interface RowProps {
    label: string | React.ReactNode;
    subLabel?: string;
    backgroundColor?: string;
    holesType?: HolesType;
    inOutForm: number;
    gen: (idx: number) => ReactNode;
    skinsClassNameGen?: (hole: number) => string;
    rightBoarderDots?: number;
}

const Label = withStyles(rowStyles)((props: { text: string | React.ReactNode, color?: string, width?: number } & WithStyles<typeof rowStyles>) => {
    const { classes, text, color, width } = props;
    return (
        <span className={classes.flex} style={{ width }}>
            <span className={classes.label} style={{ color, width }}>
                {text}
            </span>
        </span>
    );
});

const TitleLabel = withStyles(rowStyles)((props: { text: string | React.ReactNode, color?: string } & WithStyles<typeof rowStyles>) => {
    const { classes, text, color } = props;
    return (
        <span className={classes.flex}>
            <span className={classes.titleLabel} style={{ color }}>
                {text}
            </span>
        </span>
    );
});

const SubLabel = withStyles(rowStyles)((props: { text: string | React.ReactNode, color?: string } & WithStyles<typeof styles>) => {
    const { classes, text, color } = props;
    return (
        <span className={classes.flex}>
            <span className={classes.subLabel} style={{ color }}>
                {text}
            </span>
        </span>
    );
});

export const ScorecardRow = withStyles(rowStyles)((props: RowProps & WithStyles<typeof styles>) => {
    const { classes, label, subLabel, gen, holesType, inOutForm, skinsClassNameGen, rightBoarderDots } = props;
    let { backgroundColor } = props;
    const holesRange = getHolesRange(holesType);
    let color: string | undefined = undefined;
    if (backgroundColor) {
        backgroundColor = backgroundColor.toLowerCase().replace(' ', '');
        if (COLOR_NAMES.indexOf(backgroundColor) >= 0) {
            color = getContrastTextColor(backgroundColor) || 'black';
        }
    }
    const labels = label ? (
        <div style={{ width: 100 }}>
            {!subLabel && <Label text={label} color={color} width={100} />}
            {!!subLabel && <TitleLabel text={label} color={color} />}
            {!!subLabel && <SubLabel text={subLabel} color={color} />}
        </div>
    ) : null;
    const borderRadius = rightBoarderDots === 6 ? 12 : rightBoarderDots === 7 ? 4 : undefined;
    if (inOutForm === 1) {
        return (
            <div className={classes.row} style={{ backgroundColor, color }}>
                {labels}
                <span className={classes.cellsRoot}>
                    <span className={classes.cells} style={borderRadius && { borderRadius }}>
                        {holesRange.first === 0 && range(0, 9).map(i => <div className={classes.cell} style={{ color }} key={i}>{gen(i)}</div>)}
                    </span>
                    {holesRange.first === 0 && <span><span className={classes.cellsInOut} style={{ color }}>{gen(-1)}</span></span>}
                    <span className={classes.cells} style={borderRadius && { borderRadius }}>
                        {holesRange.last === 18 && range(9, 18).map(i => <div className={classes.cell} style={{ color }} key={i}>{gen(i)}</div>)}
                    </span>
                    {holesRange.last === 18 && <span><span className={classes.cellsInOut} style={{ color }}>{gen(-2)}</span></span>}
                    {!!gen(-3) && <span><span className={classes.cellsInOut} style={{ color }}>{gen(-3)}</span></span>}
                    {!!gen(-4) && <span><span className={classes.cellsInOut} style={{ color }}>{gen(-4)}</span></span>}
                </span>
            </div>
        );
    } else if (inOutForm === 2) {
        return (
            <div className={classes.row} style={{ backgroundColor, color }}>
                {labels}
                <span className={classes.cellsRoot}>
                    <span className={classes.cells}>
                        {holesRange.first === 0 && range(0, 9).map(i => <span className={classes.cell + (!!skinsClassNameGen ? skinsClassNameGen(i) : '')} style={{ color }} key={i}>{gen(i)}</span>)}
                        {holesRange.first === 0 && <span className={classes.cell} style={{ color }}>{gen(-1)}</span>}
                        {holesRange.last === 18 && range(9, 18).map(i => <span className={classes.cell + (!!skinsClassNameGen ? skinsClassNameGen(i) : '')} style={{ color }} key={i}>{gen(i)}</span>)}
                        {holesRange.last === 18 && <span className={classes.cell} style={{ color }}>{gen(-2)}</span>}
                        {!!gen(-3) && <span className={classes.cell} style={{ color }}>{gen(-3)}</span>}
                        {!!gen(-4) && <span className={classes.cell} style={{ color }}>{gen(-4)}</span>}
                    </span>
                </span>
            </div>
        );
    } else {
        return (
            <div className={classes.row} style={{ backgroundColor, color }}>
                {labels}
                <span className={classes.cellsRoot}>
                    <span className={classes.cells} style={borderRadius && { borderRadius }}>
                        {range(holesRange.first, holesRange.last).map(i => <div className={classes.cell} style={{ color }} key={i}>{gen(i)}</div>)}
                        {!!gen(-3) && <span><span className={classes.cellsInOut} style={{ color }}>{gen(-3)}</span></span>}
                        {!!gen(-4) && <span><span className={classes.cellsInOut} style={{ color }}>{gen(-4)}</span></span>}
                    </span>
                </span>
            </div>
        );
    }
});

const editStyles = (theme: Theme) => {
    const decorColor = theme.palette.grey[800];
    return {
        input: {
            width: '100%',
            height: '100%',
            boxSizing: 'border-box',
            padding: 0,
            border: 'none',
            textAlign: 'center',
            outline: 'none',
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.fontSize,
            zIndex: 1,
            background: 'none'
        },
        // inputFocused: {
        //     '&:focus': {
        //         outline: `1px solid lightgray` // was `1px solid ${theme.palette.secondary[500]}`
        //     }
        // },
        inputError: {
            backgroundColor: AppColors.errorColor
        },
        decor: {
            border: `1px solid transparent`,
            position: 'absolute',
            top: 8,
            left: 8,
            right: 8,
            bottom: 8
        },
        cellDecor: {
            border: `1px solid transparent`,
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0
        },
        dbogey: {
            backgroundColor: decorColor,
            borderColor: decorColor
        },
        'dbogey-inp': {
            color: theme.palette.getContrastText(decorColor)
        },
        bogey: {
            borderColor: decorColor
        },
        birdie: {
            borderRadius: '50%',
            borderColor: decorColor
        },
        eagle: {
            borderRadius: '50%',
            borderColor: decorColor,
            backgroundColor: decorColor
        },
        'eagle-inp': {
            color: theme.palette.getContrastText(decorColor)
        },
        handicap: {
            position: 'absolute',
            display: 'flex',
            top: 3,
            left: 8
        },
        handicapItem: {
            width: 4,
            height: 4,
            borderRadius: '50%',
            backgroundColor: decorColor,
            marginRight: 1
        },
        negative: {
            backgroundColor: 'red'
        },
        reported: {
            backgroundColor: '#CEE9D1',
        },
        pickup: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url(${pickUpImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundPositionX: 8,
            backgroundPositionY: 5,
            backgroundColor: 'transparent',
            zIndex: 0
        }
    } as StyleRules;
};

interface EditProps {
    type: 'SCORE' | 'HCP18' | 'HCP9' | '2HCP18' | '2HCP9' | 'PAR' | 'LEN' | 'POINTS';
    focused?: boolean;
    value?: number;
    onKey: (key: string) => void;
    onValue: (val?: number, isReported?: boolean, pickUp?: boolean, onPickUpChanging?: (val: boolean) => void) => void;
    onFocused?: () => void;
    diff?: Scoring.DiffName;
    handicap?: number;
    readonly?: boolean;
    error?: boolean;
    isReported?: boolean;
    trackFocus?: boolean;
    withPickUp?: boolean;
}

interface EditState {
    withPickUp: boolean;
}

type FullEditProps = EditProps & WithStyles<typeof editStyles> & { additionalDotStyles?: CSSProperties | undefined };

class EditImpl extends React.Component<FullEditProps, EditState> {
    private setWithPickUp = (withPickUp: boolean) => this.setState({ withPickUp });
    private removePickUp = () => {
        const { onValue, readonly, withPickUp, isReported } = this.props;
        if (!readonly && withPickUp !== undefined) {
            onValue(undefined, isReported, false, this.setWithPickUp);
        }
    }
    constructor(props: FullEditProps) {
        super(props);
        this.state = { withPickUp: Boolean(this.props.withPickUp) };
    }
    componentDidUpdate(prevProps: FullEditProps, prevState: EditState) {
        if (prevProps.withPickUp !== this.props.withPickUp && prevState.withPickUp === this.state.withPickUp) {
            this.setWithPickUp(Boolean(this.props.withPickUp));
        }
    }
    render() {
        const { type, value, trackFocus, focused, onFocused, onKey, onValue, classes, handicap, diff, readonly, error, isReported, additionalDotStyles } = this.props;
        let inputElement: HTMLInputElement | null = null;
        let strVal = type === 'POINTS' ?
            (value === undefined ? '0' : '' + value) :
            (!value ? '' : '' + value);
        const { withPickUp } = this.state;
        const onChange = (event: ChangeEvent<HTMLInputElement>) => {
            const newVal = event.target.value;
            if (newVal === '') {
                if (withPickUp) {
                    this.removePickUp();
                } else {
                    onValue(0, isReported);
                }
                return;
            }
            const val = parseInt(newVal, 10);
            if ((type === 'POINTS' && val >= STABLEFORD_MIN_POINT && val <= STABLEFORD_MAX_POINT) ||
                (type === 'SCORE' && val >= 1 && val <= 99) ||
                (type === 'HCP9' && val >= 1 && val <= 9) ||
                (type === 'HCP18' && val >= 1 && val <= 18) ||
                (type === '2HCP9' && val >= 1 && val <= 18) ||
                (type === '2HCP18' && val >= 1 && val <= 36) ||
                (type === 'PAR' && val >= 3 && val <= 5) ||
                (type === 'LEN' && val >= 0 && val <= 999)) {
                onValue(val);
            } else if (type === 'PAR') {
                onValue();
            }
        }
        const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
            const { withPickUp, isReported } = this.props;
            if ((e.key === '#') && withPickUp !== undefined && type === 'SCORE') {
                e.preventDefault();
                onValue(undefined, isReported, true, this.setWithPickUp);
            } else if (e.key === KEY_ARROWUP || e.key === KEY_ARROWDOWN) {
                e.preventDefault();
                onKey(e.key);
            } else if (inputElement) {
                if (inputElement.selectionStart === 0 && (e.key === KEY_ARROWLEFT || e.key === KEY_BACKSPACE)) {
                    e.preventDefault();
                    onKey(KEY_ARROWLEFT);
                    return;
                }
                if (inputElement.selectionEnd === (this.state.withPickUp ? specialSymbol.length : strVal.length) && e.key === KEY_ARROWRIGHT) {
                    e.preventDefault();
                    onKey(KEY_ARROWRIGHT);
                    return;
                }
                if (isReported && ((e.key >= '0' && e.key <= '9') || (e.key >= '`' && e.key <= 'i') || e.key === specialSymbol)) {
                    inputElement.value = '';
                    onKey(e.key);
                    return;
                }
            }
        }
        const onPickUpEnteringEventListener = (e: any) => {
            if (e instanceof InputEvent && e.data === '#') {
                e.preventDefault();
                onValue(undefined, this.props.isReported, true, this.setWithPickUp);
            }
        }
        const setFocus = (ip: HTMLInputElement | null) => {
            if (inputElement && inputElement !== ip) {
                inputElement.removeEventListener('input', onPickUpEnteringEventListener);
            }
            if (ip) {
                const prevInputElement = inputElement;
                inputElement = ip;
                if (prevInputElement !== inputElement) {
                    inputElement.addEventListener('input', onPickUpEnteringEventListener);
                }
            }
            if (!readonly && ip && focused) {
                ip.focus();
            }
        }
        const hSign = (handicap || 0) < 0 ? 'negative' : '';
        return <>
            <div className={`${classes.cellDecor} ${isReported ? classes.reported : ''}`} />
            {!withPickUp && <div className={`${classes.decor} ${classes[diff || '']}`} />}
            <div className={classes.handicap} style={additionalDotStyles}>
                {range(0, handicap || 0).map(i => <div key={i} className={`${classes.handicapItem} ${classes[hSign]}`} />)}
            </div>
            <input value={withPickUp ? specialSymbol : strVal}
                onChange={onChange}
                onFocus={_ => {
                    if (trackFocus && onFocused) {
                        onFocused();
                    }
                }}
                type="text"
                inputMode="tel"
                onKeyDown={onKeyDown}
                readOnly={readonly}
                className={`${classes.input} ${classes[diff + '-inp']} ${readonly ? '' : classes.inputFocused} ${error ? classes.inputError : ''} ${withPickUp ? classes.pickup : ''}`}
                style={(this.state.withPickUp && !readonly) ? { caretColor: 'black' } : undefined}
                ref={ip => setFocus(ip)} />
        </>;
    }
}

export const Edit = withStyles(editStyles)(EditImpl);

interface ScoreDialogProps {
    open: boolean;
    readonly?: boolean;
    close: () => void;
    rounds: Array<Round>;
    competition: Competition;
    golfers: Map<string, Contact>;
    handleContactChanged?: (contactDetails: ContactDetails) => void;
    handleContactDeleted?: (contacts: Array<Contact>) => void;
    mainCompetition?: Competition;
    roundScorecardLink?: string;
    skinsGross?: SkinsScoringState[];
    skinsNet?: SkinsScoringState[];
    eventRoot: EventBase;
    eventData?: EventData;
}

interface EditScoreState {
    reportedScores: Map<string, ReportedScore>;
    scores: Map<string, Score>;
    focusedId: string;
    focusedIdx: number;
    wDorDQMenuOpen?: boolean;
    changed?: boolean;
    withdrawn?: boolean;
    disqualified?: boolean;
    markVerified: boolean;
    confirmingCancel?: () => void;
    editedContact?: ContactDetails;
    contacts: Array<Contact>;
    anchorWDorDQ: (EventTarget & HTMLElement) | undefined;
}

type EditScoreProps = ScoreDialogProps & ContactScoringState & WithStyles<typeof styles> & WithPossibleUserAware;

class ScoreDialog extends React.Component<EditScoreProps, EditScoreState> {
    state: EditScoreState = {
        reportedScores: new Map<string, ReportedScore>(),
        scores: new Map<string, Score>(),
        focusedId: '',
        focusedIdx: 0,
        withdrawn: this.props.contactInfo.withdrawn || false,
        disqualified: this.props.contactInfo.disqualified || false,
        markVerified: true,
        contacts: this.props.contactInfo.contacts,
        anchorWDorDQ: undefined
    };

    private score: Score = { gross: new Array<number>(18).fill(0), pickUps: new Array<boolean>(18).fill(false) } as Score;
    private mergedScoresMap = new Map<string, Score>();
    private holesRange = getHolesRange(this.props.eventOrRound.holesType);

    private onReportedScore = (id: string, score: ReportedScore) => {
        const { reportedScores } = this.state;
        if (!score) {
            score = { id: id, strokes: [], watched: [] };
        } else {
            score.id = id;
        }
        if (!score.strokes) {
            score.strokes = [];
        }
        for (let hole = this.holesRange.first; hole < this.holesRange.last; hole++) {
            if (!score.strokes[hole]) {
                score.strokes[hole] = 0;
            }
        }
        reportedScores.set(id, score);
        this.setState({ reportedScores: reportedScores }, () => this.updateFocus(id));
    }

    private onScore = (id: string, score: Score) => {
        const { scores } = this.state;
        if (!score) {
            score = { id: id, gross: [] };
        } else {
            score.id = id;
        }
        if (!score.gross) {
            score.gross = [];
        }
        for (let hole = this.holesRange.first; hole < this.holesRange.last; hole++) {
            if (!score.gross[hole]) {
                score.gross[hole] = 0;
            }
        }
        scores.set(id, score);
        this.setState({ scores }, () => this.updateFocus(id));
    }

    private updateFocus = (id: string) => {
        let { focusedId, focusedIdx } = this.state;
        if ('' === focusedId) {
            focusedId = id;
        }
        if (id === focusedId) {
            const { scores, reportedScores } = this.state;
            const score = scores.get(id);
            const reportedScore = reportedScores.get(id);
            const focusedIdx1 = score ? Math.max(this.holesRange.first, score.gross.findIndex((s, i) => !s && i >= this.holesRange.first)) : focusedIdx;
            const focusedIdx2 = reportedScore ? Math.max(this.holesRange.first, reportedScore.strokes.findIndex((s, i) => !s && i >= this.holesRange.first)) : focusedIdx;
            focusedIdx = Math.max(focusedIdx1, focusedIdx2);
        }
        this.setState({ focusedId, focusedIdx });
    }

    private onContact = (id: string, contact: Contact) => {
        const { player, contactInfo, competition } = this.props;
        if (competition.scoring.format !== 'best_ball' && player.id === id) {
            contactInfo.handicapIndex = contact.handicapIndex;
        }
        let { contacts } = this.state;
        contacts.filter(c => c.id === id).forEach(c => Object.assign(c, contact));
        contacts = contacts.filter(c => !c.hidden);
        if (contacts.length === 0) {
            this.setState({ contacts }, this.handleClose);
        } else {
            this.setState({ contacts });
        }
    }

    private handleClear = () => {
        const { scores, reportedScores } = this.state;
        scores.forEach(score => {
            score.gross.fill(0);
            score.pickUps?.fill(false);
        });
        reportedScores.forEach(reportedScores => {
            reportedScores.strokes.fill(0);
            reportedScores.pickUps?.fill(false);
        });
        this.setState({ scores, reportedScores, changed: true, focusedIdx: 0 });
    }

    private handleSave = () => {
        const { eventRoot, rounds, eventOrRound, player, competition, mainCompetition } = this.props;
        const { scores, reportedScores, withdrawn, disqualified, markVerified, contacts } = this.state;
        const scoring = competition.scoring;
        const skinsTeam = scoring.format === ScoringFormatSkins.skins_team;
        const skinsFromTeamScores = skinsTeam && mainCompetition?.scoring.format === ScoringFormatTeams.best_ball;
        if (skinsFromTeamScores) {
            return;
        }
        const now = Date.now();
        const updated: Array<Score> = [];
        const reportedToSave: Array<ReportedScore> = [];
        const hideProgress = showProgress('save_scores');
        reportedScores.forEach(reportedScore => {
            validateScores(reportedScore.strokes);
            reportedToSave.push(reportedScore);
        });
        if (markVerified) {
            reportedScores.forEach(reported => {
                const holesAmount = reported.strokes.length;
                if (!scores.has(reported.id)) {
                    scores.set(reported.id, { id: reported.id, gross: new Array<number>(holesAmount).fill(0) });
                }
                const score = scores.get(reported.id)!;
                if (!score.pickUps) {
                    score.pickUps = new Array<boolean>(holesAmount).fill(false);
                }
                for (let i = 0; i < reported.strokes.length; i++) {
                    if (Scoring.markHoleAsReported(i, score, reported) && (!!reported.strokes[i] || (reported.pickUps && reported.pickUps[i] != null))) {
                        score.gross[i] = reported.strokes[i];
                        score.pickUps[i] = reported.pickUps ? reported.pickUps[i] : false;
                    }
                }
            });
        }
        reportedScores.forEach(reportedScore => {
            validateScores(reportedScore.strokes);
            reportedScore.watched = reportedScore.strokes;
            reportedScore.watchedPickUps = reportedScore.pickUps;
            reportedToSave.push(reportedScore);
        });
        scores.forEach(score => {
            validateScores(score.gross);
            score.updateTime = now;
            updated.push(score);
        });
        const reportedDb = (isTeamScoringExceptBB(scoring) || skinsTeam) ? Backend.reportedTeamScoresDb(eventOrRound.id) : Backend.reportedGolferScoresDb(eventOrRound.id);
        const scoresDb = (isTeamScoringExceptBB(scoring) || skinsTeam) ? Backend.golferTeamScoresDb(eventOrRound.id) : Backend.golferScoresDb(eventOrRound.id);
        //const playerDb = (isTeamScoring(scoring) || skinsTeam) ? Backend.golferTeamDb(eventOrRound.id) : Backend.golferDb(eventOrRound.id);
        const playerUpdate = isTeamScoringExceptBB(scoring) || skinsTeam ?
            { id: player.id, withdrawn, disqualified, exists: true } as Team :
            { id: player.id, withdrawn, disqualified, exists: true } as Contact;
        Backend.trackEvent('save_scores');
        Backend.updateOrAddBatch(reportedDb, reportedToSave, true)
            .then(() => Backend.updateOrAddBatch(scoresDb, updated, true))
            .then(() => updateGolferDisStatus(eventRoot, rounds, playerUpdate, isTeamScoring(scoring) || skinsTeam))
            .then(() => updated.forEach(score =>
                elog(eventOrRound, `Scores edited`, `${[...contacts.map(contact => contact.firstName + ' ' + contact.lastName)]}: ${[...score.gross]}`, `Id: ${score.id}`)))
            .then(() => {
                hideProgress();
                this.props.close();
            })
            .catch(err => hideProgress(errMsg(err)));
    }

    private static handlePickUpRemoving(hole: number, score: Score | ReportedScore) {
        if (score.pickUps) {
            score.pickUps[hole] = false;
            if (!score.pickUps.includes(true)) {
                score.pickUps = undefined;
            }
        }
    }

    private handleKey = (id: string, hole: number) => (key: string) => {
        if (key === KEY_ARROWLEFT) {
            const focusedIdx = (hole > this.holesRange.first) ? (hole - 1) : hole;
            this.setState({ focusedId: id, focusedIdx });
        } else if (key === KEY_ARROWRIGHT) {
            const focusedIdx = (hole < this.holesRange.last - 1) ? (hole + 1) : hole;
            this.setState({ focusedId: id, focusedIdx });
        } else if (key === KEY_ARROWDOWN || key === KEY_ARROWUP) {
            const { competition } = this.props;
            const { contacts } = this.state;
            if (competition.scoring.format === 'best_ball') {
                const contactIds = contacts.map(c => c.id);
                for (let i = 0; i < contactIds.length; i++) {
                    if (id === contactIds[i]) {
                        if (key === KEY_ARROWUP) {
                            if (i > 0) {
                                this.setState({ focusedId: contactIds[i - 1] });
                            }
                        } else if (key === KEY_ARROWDOWN) {
                            if (i < contactIds.length - 1) {
                                this.setState({ focusedId: contactIds[i + 1] });
                            }
                        }
                        break;
                    }
                }
            }
        }
    }

    private handleScore = (id: string, hole: number) => (val?: number, isReported?: boolean, pickUp?: boolean, onPickUpChanging?: (val: boolean) => void) => {
        const { reportedScores, scores } = this.state;
        val = val || 0;
        if (val === 0 && reportedScores && reportedScores.has(id) && reportedScores.get(id)!.strokes[hole] > 0 &&
            (!scores.has(id) || scores.get(id)!.gross[hole] === 0)) {
            reportedScores.get(id)!.strokes[hole] = 0;
        }
        if (val >= 0) {
            const { scores, reportedScores } = this.state;
            if (!scores.has(id)) {
                scores.set(id, { id: id, gross: [] });
            }
            const focusedId = id;
            const score: Score = scores.get(id)!;
            const reportedScore: ReportedScore | undefined = reportedScores.get(id);
            let focusedIdx: number = (val > 1) ? (hole + 1) : hole;
            if (pickUp) {
                if (!score.pickUps) {
                    score.pickUps = new Array<boolean>(score.gross.length).fill(false);
                }
                score.pickUps[hole] = true;
                focusedIdx = hole < this.holesRange.last - 1 ? (hole + 1) : hole;
            } else if (pickUp === false) {
                if (score.pickUps && score.pickUps[hole]) {
                    ScoreDialog.handlePickUpRemoving(hole, score);
                    if (reportedScore?.pickUps && reportedScore.pickUps[hole]) {
                        pickUp = undefined;
                    }
                } else if (reportedScore?.pickUps && reportedScore.pickUps[hole]) {
                    ScoreDialog.handlePickUpRemoving(hole, reportedScore);
                    if (isReported && !val) {
                        reportedScore.strokes[hole] = val;
                    }
                }
                score.gross[hole] = val;
                focusedIdx = hole;
            } else {
                if (isReported && !val && reportedScore && reportedScore.strokes[hole] > 0) {
                    reportedScore.strokes[hole] = val;
                } else {
                    score.gross[hole] = val;
                }
            }
            if (pickUp !== undefined && !!onPickUpChanging) {
                onPickUpChanging(pickUp);
            }
            this.setState({ scores, reportedScores, focusedId, focusedIdx, changed: true });
        }
    }

    private handleMarkChange = (checked: boolean) => {
        this.setState({ markVerified: checked });
    };

    private handleClose = () => {
        const { readonly, close } = this.props;
        const { changed } = this.state;
        if (readonly || !changed) {
            close();
        } else {
            this.setState({ confirmingCancel: () => this.setState( { confirmingCancel: undefined } )});
        }
    }

    scoreDocs = () => {
        const { eventOrRound, player, competition, mainCompetition } = this.props;
        const { contacts } = this.state;
        const scoring = competition.scoring;
        const skinsFromTeamScores = scoring.format === ScoringFormatSkins.skins_team && mainCompetition?.scoring.format !== ScoringFormatTeams.best_ball;
        return <>
            {contacts.map(golfer =>
                <FirebaseDocComponent
                    key={golfer.id}
                    onData={data => this.onContact(golfer.id, data as Contact)}
                    docReference={Backend.doc(Backend.golferDb(eventOrRound.id), golfer.id)} />)}
            {scoring.format === 'best_ball' ? contacts.map(golfer =>
                <FirebaseDocComponent
                    key={golfer.id}
                    onData={data => this.onScore(golfer.id, data as Score)}
                    docReference={Backend.doc(Backend.golferScoresDb(eventOrRound.id), golfer.id)} />) :
                <FirebaseDocComponent onData={data => this.onScore(player.id, data as Score)}
                    docReference={(isTeamScoringExceptBB(scoring) || skinsFromTeamScores) ?
                        Backend.doc(Backend.golferTeamScoresDb(eventOrRound.id), player.id) :
                        Backend.doc(Backend.golferScoresDb(eventOrRound.id), player.id)} />}
            {scoring.format === 'best_ball' ? contacts.map(golfer =>
                <FirebaseDocComponent
                    key={golfer.id}
                    onData={data => this.onReportedScore(golfer.id, data as ReportedScore)}
                    docReference={Backend.doc(Backend.reportedGolferScoresDb(eventOrRound.id), golfer.id)} />) :
                <FirebaseDocComponent
                    onData={data => this.onReportedScore(player.id, data as ReportedScore)}
                    docReference={(isTeamScoringExceptBB(scoring) || skinsFromTeamScores) ?
                        Backend.doc(Backend.reportedTeamScoresDb(eventOrRound.id), player.id) :
                        Backend.doc(Backend.reportedGolferScoresDb(eventOrRound.id), player.id)} />}
        </>;
    }

    private scoresOf(id: string): Score {
        const { eventOrRound, player, competition } = this.props;
        const { contacts, scores } = this.state;
        return scoresOf(id, eventOrRound, player.id, competition, contacts, scores);
    }

    private reportedScoresOf(id: string): ReportedScore {
        const { eventOrRound, player, competition } = this.props;
        const { contacts, reportedScores } = this.state;
        return reportedScoresOf(id, eventOrRound, player.id, competition, contacts, reportedScores);
    }

    private scoreSumOf(id: string, holeStart: number, holeEnd: number): number {
        const res = this.scoresOf(id);
        let sum = 0;
        for (let hole = holeStart; hole < holeEnd; hole++) {
            sum += res.gross[hole] || 0;
        }
        return sum;
    }

    private showPlayerScoresInfo = (e: React.SyntheticEvent) => {
        e.preventDefault();
        e.stopPropagation();
        const { classes } = this.props;
        showAlert(<>
            Golfers can post scores using the Golf Pad app on their phones. Scores submitted by golfers are highlighted in green.
            You can review and verify the scores by opening each golfer's scorecard.
            Any scores entered by you (the organizer) will supersede golfer-submitted scores. <a href="https://support.golfpadgps.com/a/solutions/articles/6000245736" target="blank" className={classes.linkBlue}>Learn more</a>
        </>);
    }

    private WDorDQMenu = () => {
        const { withdrawn, disqualified } = this.state;
        const individual = this.props.competition.scoring.format in ScoringFormatIndividual;
        return (
            <Popper anchorEl={this.state.anchorWDorDQ} transition open={Boolean(this.state.wDorDQMenuOpen) && Boolean(this.state.anchorWDorDQ)} style={{ zIndex: 1500 }}>
                {({ TransitionProps, placement }) => (
                    <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                        <Paper>
                            <ClickAwayListener onClickAway={this.handleWDorDQMenuClose}>
                                <MenuList role="menu">
                                    {(!withdrawn) && <MenuItem onClick={this.handleWithdrawn}>{(individual ? 'Golfer' : 'Team') + ' withdrawn / Did not finish'}</MenuItem>}
                                    {(!disqualified) && <MenuItem onClick={this.handleDisqualified}>{(individual ? 'Golfer' : 'Team') + ' disqualified'}</MenuItem>}
                                    {(withdrawn || disqualified) && <MenuItem onClick={this.handleReset}>Reset</MenuItem>}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        );
    }

    private handleWDorDQClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => this.setState({ wDorDQMenuOpen: !this.state.wDorDQMenuOpen, anchorWDorDQ: event.currentTarget });

    private handleWDorDQMenuClose = () => this.setState({ wDorDQMenuOpen: false });

    private handleWithdrawn = () => {
        const { contactInfo } = this.props;
        let { withdrawn, disqualified, changed } = this.state;
        if (disqualified) {
            disqualified = false;
        }
        withdrawn = true;
        this.setState({ wDorDQMenuOpen: false, withdrawn, disqualified, changed: withdrawn !== (contactInfo.withdrawn || false) || disqualified !== (contactInfo.disqualified || false) || changed });
    }

    private handleDisqualified = () => {
        const { contactInfo } = this.props;
        let { withdrawn, disqualified, changed } = this.state;
        if (withdrawn) {
            withdrawn = false;
        }
        disqualified = true;
        this.setState({ wDorDQMenuOpen: false, withdrawn, disqualified, changed: withdrawn !== (contactInfo.withdrawn || false) || disqualified !== (contactInfo.disqualified || false) || changed });
    }

    private handleReset = () => {
        const { contactInfo } = this.props;
        let { withdrawn, disqualified, changed } = this.state;
        withdrawn = false;
        disqualified = false;
        this.setState({ wDorDQMenuOpen: false, withdrawn, disqualified, changed: withdrawn !== (contactInfo.withdrawn || false) || disqualified !== (contactInfo.disqualified || false) || changed });
    }

    private editPlayer = (contact: Contact) => this.setState({ editedContact: contact })

    private onContactChanged = (contactDetails: ContactDetails) => {
        const { handleContactChanged } = this.props;
        if (handleContactChanged) {
            handleContactChanged(contactDetails);
            this.setState({ editedContact: undefined });
        }
    }

    private onContactDeleted = (contacts: Array<Contact>) => {
        const { handleContactDeleted } = this.props;
        if (handleContactDeleted) {
            handleContactDeleted(contacts)
        }
    }

    renderScores(args: { playingHandicap: number; isReported: boolean; tees: Array<Tee | undefined | null>; usedTee?: Tee | null; }) {
        const { tees, usedTee, playingHandicap, isReported } = args;
        const { classes, eventOrRound, contactInfo, readonly, competition, userAware, golfers, mainCompetition, skinsGross, skinsNet } = this.props;
        const { finalGrossScore, nets: netScores, total, relativeTotal, net, relativeNet, tee, player } = this.props;
        const { scores, reportedScores, focusedId, focusedIdx, markVerified, withdrawn, disqualified, contacts } = this.state;
        const scoring = competition.scoring;
        const isGross = isGrossMode(scoring.mode);
        const isNet = isNetMode(scoring.mode);
        const isStableford = isStablefordScoringOrMode(scoring);
        const bestBall = scoring.format === ScoringFormatTeams.best_ball;
        const isSkins = isSkinsScoring(scoring);
        const { par, len, handicap, handicap2, handicapSystem } = tee || usedTee || {} as Tee;
        const totalHoles = this.holesRange.last - this.holesRange.first;
        const reportedScore = this.reportedScoresOf(player.id);
        const rawScore = this.scoresOf(player.id);
        this.score = readonly ? finalGrossScore : Scoring.mergeScores(false, rawScore, reportedScore, usedTee?.par, true);
        let nets = netScores;
        if (!readonly) {
            if (bestBall) {
                const teamContacts = golfersOfTeam(player as Team, golfers);
                const teamContactTees = teamContacts.map(c => getTee(eventOrRound, competition, c.gender, c));
                nets = Scoring.bestBallNet(handicapSystem, contacts, contactInfo.playingHandicaps, scores, reportedScores, teamContactTees, eventOrRound.holesType);
            } else {
                nets = Scoring.getNets(handicapSystem, playingHandicap, this.score, reportedScore, eventOrRound.holesType, tee);
            }
        }
        const stableford: Array<number | undefined> = [];
        const stablefordNet: Array<number | undefined> = [];
        let totPoints: number | string = 0;
        let totNetPoints: number | string = 0;
        let stablefordSum1 = 0;
        let stablefordSum2 = 0;
        let stablefordNetSum1 = 0;
        let stablefordNetSum2 = 0;
        const showReported = !!userAware?.workingUserId;
        const disOrWit = withdrawn ? 'WD' : disqualified ? 'DQ' : undefined;
        const hs = eventOrRound.handicapSystem ?? (handicapSystem ?? 'WHS');
        if (isStableford) {
            const stablefordPoints = Scoring.getStablefordPoints(scoring);
            for (let hole = this.holesRange.first; hole < this.holesRange.last; ++hole) {
                const pt = Scoring.relativeStablefordPoints(hole, this.score, reportedScore, stablefordPoints, eventOrRound.holesType, usedTee);
                stableford[hole] = pt;
                if (hole >= 0 && hole < 9) {
                    stablefordSum1 += pt || 0;
                }
                if (hole >= 9 && hole < 18) {
                    stablefordSum2 += pt || 0;
                }
                if (isNet) {
                    let ptNet: number | undefined;
                    if (bestBall) {
                        ptNet = Scoring.relativeStablefordNetPoints(hs, hole, 0, { gross: nets } as Score, reportedScore, stablefordPoints, eventOrRound.holesType, usedTee);
                    } else {
                        ptNet = Scoring.relativeStablefordNetPoints(hs, hole, playingHandicap, this.score, reportedScore, stablefordPoints, eventOrRound.holesType, usedTee);
                    }
                    stablefordNet[hole] = ptNet;
                    if (hole >= 0 && hole < 9) {
                        stablefordNetSum1 += ptNet || 0;
                    }
                    if (hole >= 9 && hole < 18) {
                        stablefordNetSum2 += ptNet || 0;
                    }
                }
            }
            totPoints = !disOrWit ? Scoring.relativeTotalStablefordPoints(this.score, reportedScore, stablefordPoints, eventOrRound.holesType, usedTee) : disOrWit;
            if (isNet) {
                if (bestBall) {
                    totNetPoints = !disOrWit ? Scoring.relativeTotalStablefordNetPoints(hs, 0, { gross: nets } as Score, reportedScore, stablefordPoints, eventOrRound.holesType, usedTee) : disOrWit;
                } else {
                    totNetPoints = !disOrWit ? Scoring.relativeTotalStablefordNetPoints(hs, playingHandicap, this.score, reportedScore, stablefordPoints, eventOrRound.holesType, usedTee) : disOrWit;
                }
            }
        }
        if (bestBall) {
            contacts.forEach(contact => this.mergedScoresMap.set(contact.id, Scoring.mergeScores(Boolean(readonly) && eventOrRound.hideLiveScores === 'VERIFIED', this.scoresOf(contact.id), this.reportedScoresOf(contact.id), usedTee?.par, true)));
            this.mergedScoresMap.set(player.id, this.score);
        }
        const totGross = readonly ? total : Scoring.totalGross(this.score, reportedScore, eventOrRound.holesType);
        const totGrossRel = readonly ? relativeTotal : Scoring.relativeGrossTotal(this.score, reportedScore, eventOrRound.holesType, usedTee);
        let individualsNetScores: Array<number | undefined> = [];
        let totNet = 0;
        let totNet1 = 0;
        let totNet2 = 0;
        let totNetRel = 0;
        let totBestballNet1 = 0;
        let totBestballNet2 = 0;
        if (bestBall && isNet) {
            if (readonly && nets) {
                individualsNetScores = nets;
                totNet = net;
                totNetRel = relativeNet;
            } else {
                const playingHandicaps = contacts.map((teamContact, teamIndex) => {
                    return getGolferPlayingHandicap(teamContact, eventOrRound, competition, teamIndex);
                });
                individualsNetScores = Scoring.bestBallNet(hs, contacts, playingHandicaps, scores, reportedScores, tees, eventOrRound.holesType);
                totNet = Scoring.totalNetBase(individualsNetScores, eventOrRound.holesType);
                totNetRel = Scoring.relativeNetTotalBase(individualsNetScores, eventOrRound.holesType, usedTee);
            }
            for (let hole = 0; hole < 9; hole++) {
                totBestballNet1 += individualsNetScores[hole] || 0;
            }
            for (let hole = 9; hole < 18; hole++) {
                totBestballNet2 += individualsNetScores[hole] || 0;
            }
        } else if (isNet) {
            if (readonly && nets) {
                totNet = net;
                totNetRel = relativeNet;
            } else {
                totNet = Scoring.totalNet(hs, playingHandicap, this.score, reportedScore, eventOrRound.holesType, usedTee);
                totNetRel = Scoring.relativeNetTotal(hs, playingHandicap, this.score, reportedScore, eventOrRound.holesType, usedTee);
            }
            for (let hole = 0; hole < 9; hole++) {
                totNet1 += Scoring.scoreNet(hs, hole, playingHandicap, this.score, reportedScore, eventOrRound.holesType, usedTee) || 0;
            }
            for (let hole = 9; hole < 18; hole++) {
                totNet2 += Scoring.scoreNet(hs, hole, playingHandicap, this.score, reportedScore, eventOrRound.holesType, usedTee) || 0;
            }
        }
        const titleGross = bestBall ? 'TEAM GROSS' : 'GROSS';
        const titleTotalNet = bestBall ? 'TEAM NET' : 'NET';
        const readonlyTeam = bestBall || readonly;
        const grossValue = !disOrWit ? (totGross + (totGross ? ' (' + Scoring.formatRelativeScore(totGrossRel) + ')' : '')) : disOrWit;
        const netValue = !disOrWit ? (totNet + (totNet ? ' (' + Scoring.formatRelativeScore(totNetRel) + ')' : '')) : disOrWit;
        const courseHoleCount = eventOrRound.holesType === HOLES_9 || eventOrRound.holesType === HOLES_9_9 ? 9 : 18;
        let parSum1 = 0;
        let parSum2 = 0;
        if (par) {
            for (let hole = 0; hole < 9; hole++) {
                parSum1 += par[hole % courseHoleCount] || 0;
            }
            for (let hole = 9; hole < 18; hole++) {
                parSum2 += par[hole % courseHoleCount] || 0;
            }
        }
        let lenSum1 = 0;
        let lenSum2 = 0;
        if (len) {
            for (let hole = 0; hole < 9; hole++) {
                lenSum1 += len[hole % courseHoleCount] || 0;
            }
            for (let hole = 9; hole < 18; hole++) {
                lenSum2 += len[hole % courseHoleCount] || 0;
            }
        }
        let totGross1 = 0;
        let totGross2 = 0;
        if (this.score) {
            for (let hole = 0; hole < 9; hole++) {
                totGross1 += this.score.gross[hole] ?? 0;
            }
            for (let hole = 9; hole < 18; hole++) {
                totGross2 += this.score.gross[hole] ?? 0;
            }
        }
        const displayHCP = handicap ? (hole: number) => {
            if (hole < 0) {
                return '';
            }
            if (eventOrRound.holesType === HOLES_9_9) {
                if (hole < 9) {
                    if (eventOrRound.handicapSystem === 'WHS_AU' && playingHandicap > totalHoles) {
                        return !!handicap2 && !!handicap2[hole % courseHoleCount] ? 2 * handicap2[hole % courseHoleCount] - 1 : undefined;
                    } else {
                        return 2 * handicap[hole % courseHoleCount] - 1;
                    }
                } else {
                    if (eventOrRound.handicapSystem === 'WHS_AU' && playingHandicap > totalHoles) {
                        return !!handicap2 && !!handicap2[hole % courseHoleCount] ? (2 * handicap2[hole % courseHoleCount]) : undefined;
                    } else {
                        return 2 * handicap[hole % courseHoleCount];
                    }
                }
            }
            if (totalHoles === 9 && usedTee?.handicap.length === 18) {
                if (eventOrRound.handicapSystem === 'WHS_AU' && playingHandicap > totalHoles) {
                    return !!handicap2 && !!handicap2[hole] ? Math.round(handicap2[hole] / 2) : undefined;
                } else {
                    return Math.round(handicap[hole] / 2);
                }
            }
            if (eventOrRound.handicapSystem === 'WHS_AU' && playingHandicap > totalHoles) {
                return !!handicap2 && !!handicap2[hole] ? handicap2[hole] : undefined;
            } else {
                return handicap[hole];
            }
        } : undefined;
        const holeLabelsGen = (hole: number) => {
            if (hole === -1) {
                return 'OUT';
            }
            if (hole === -2) {
                return 'IN';
            }
            if (hole < 0) {
                return '';
            }
            return getHoleLabel(hole, this.holesRange);
        };
        const parLabelsGen = (hole: number) => {
            if (hole === -1) {
                return parSum1;
            }
            if (hole === -2) {
                return parSum2;
            }
            if (hole < 0) {
                return '';
            }
            return par ? par[hole % courseHoleCount] : '';
        }
        const lenLabelsGen = (hole: number) => {
            if (hole === -1) {
                return lenSum1;
            }
            if (hole === -2) {
                return lenSum2;
            }
            if (hole < 0) {
                return '';
            }
            return len && len[hole % courseHoleCount] ? len[hole % courseHoleCount] : '';
        }
        const bestBallGen = (hole: number, golfer: Contact, gi: number) => {
            if (hole === -1) {
                return this.scoreSumOf(golfer.id, 0, 9);
            }
            if (hole === -2) {
                return this.scoreSumOf(golfer.id, 9, 18);
            }
            if (hole < 0) {
                return '';
            }
            const bestBallScore = this.mergedScoresMap.get(golfer.id);
            const bestBallHoleScore = bestBallScore ? bestBallScore.gross[hole] : 0;
            const handicap = (isNet && getHoleHandicap(hole, eventOrRound.holesType, tees[gi], eventOrRound.handicapSystem, getGolferRangeHandicap(eventOrRound.holesType, eventOrRound.handicapSystem, golfer.handicapIndex), getHandicapsAllowance(golfer, scoring, gi))) || 0;
            return (<Edit
                type={'SCORE'}
                focused={hole === focusedIdx && golfer.id === focusedId}
                value={bestBallHoleScore}
                onKey={this.handleKey(golfer.id, hole)}
                onValue={this.handleScore(golfer.id, hole)}
                handicap={handicap}
                diff={Scoring.getScoreDiffName(bestBallHoleScore, par && par[hole % courseHoleCount])}
                readonly={readonly}
                isReported={showReported && Scoring.markHoleAsReported(hole, scores.get(golfer.id), reportedScores.get(golfer.id))}
                withPickUp={bestBallScore?.pickUps && bestBallScore.pickUps[hole]}
                additionalDotStyles={handicap === 7 && hole % 9 === 8 ? { left: 6 } : undefined}
                key={hole} />);
        };
        const grossScoresGen = (hole: number) => {
            if (hole === -1) {
                return totGross1;
            }
            if (hole === -2) {
                return totGross2;
            }
            if (hole < 0) {
                return '';
            }
            const holeScore = this.score.gross ? this.score.gross[hole] : 0;
            const handicap = (isNet && !bestBall && getHoleHandicap(hole, eventOrRound.holesType, usedTee, eventOrRound.handicapSystem, getGolferRangeHandicap(eventOrRound.holesType, eventOrRound.handicapSystem, contactInfo.handicapIndex), getHandicapsAllowance(contactInfo, scoring, 0), playingHandicap)) || 0;
            return (<Edit
                type={'SCORE'}
                focused={hole === focusedIdx && player.id === focusedId}
                value={holeScore}
                onKey={this.handleKey(player.id, hole)}
                onValue={this.handleScore(player.id, hole)}
                handicap={handicap}
                diff={Scoring.getScoreDiffName(holeScore, par && par[hole % courseHoleCount])}
                readonly={readonlyTeam}
                isReported={showReported && Scoring.markHoleAsReported(hole, rawScore, reportedScore)}
                withPickUp={this.score.pickUps && this.score.pickUps[hole]}
                additionalDotStyles={handicap === 7 && hole % 9 === 8 ? { left: 6 } : undefined}
                key={hole} />);
        };
        const netBestballScoresGen = (hole: number) => {
            if (hole === -1) {
                return totBestballNet1;
            }
            if (hole === -2) {
                return totBestballNet2;
            }
            if (hole < 0) {
                return '';
            }
            return displayScore(individualsNetScores[hole]);
        }
        const netScoresGen = (hole: number) => {
            if (hole === -1) {
                return totNet1;
            }
            if (hole === -2) {
                return totNet2;
            }
            if (hole < 0) {
                return '';
            }
            return displayScore(nets ? nets[hole] : Scoring.scoreNet(hs, hole, playingHandicap, rawScore, reportedScore, eventOrRound.holesType, usedTee));
        }
        const stablefordScoresGen = (hole: number) => {
            if (hole === -1) {
                return stablefordSum1;
            }
            if (hole === -2) {
                return stablefordSum2;
            }
            if (hole < 0) {
                return '';
            }
            return displayScore(stableford[hole]);
        }
        const stablefordNetScoresGen = (hole: number) => {
            if (hole === -1) {
                return stablefordNetSum1;
            }
            if (hole === -2) {
                return stablefordNetSum2;
            }
            if (hole < 0) {
                return '';
            }
            return displayScore(stablefordNet[hole]);
        }
        const inOut = totalHoles === 18 ? 1 : 0;
        const scrollStyle = inOut ? (totalHoles === 18 ? classes.scorecard20 : classes.scorecard10) : (totalHoles === 18 ? classes.scorecard18 : classes.scorecard9);
        const individual = scoring.format in ScoringFormatIndividual;
        let withPickUpInfo: boolean = false;
        if (readonly) {
            for (const score of Array.from(scores.values())) {
                if (score.pickUps && score.pickUps.includes(true)) {
                    withPickUpInfo = true;
                    break;
                }
            }
        }
        const carryOversSkins = skinsWithCarryOvers(competition);
        let totalSkins1 = 0;
        let totalSkins2 = 0;
        const interceptSkinsGenResult = (hole: number, genResult: number | string) => {
            if (typeof genResult === 'number') {
                if (hole < 9) {
                    totalSkins1 += genResult;
                } else {
                    totalSkins2 += genResult;
                }
            }
            return genResult;
        }
        const getSkinsGen = (skins: SkinsScoringState[]) => (hole: number): number | string => {
            if (hole === -1) {
                return totalSkins1;
            }
            if (hole === -2) {
                return totalSkins2;
            }
            if (hole < this.holesRange.first || hole > this.holesRange.last) {
                return '';
            }
            if (hole === this.holesRange.first) {
                totalSkins1 = 0;
                totalSkins2 = 0;
            }
            const skin = getActualSkin(hole, this.holesRange, skins);
            if (!skin || skin.contacts.length !== 1 || skin.contacts[0].golferOrTeamId !== player.id) {
                return 0;
            }
            const holesWon = carryOversSkins ? evalWonHolesNumAroundIndex(skins, hole) : 1;
            return totalHoles === 18 ? interceptSkinsGenResult(hole, holesWon) : holesWon;
        }
        const textElem = (txt1: any, txt2: any) => <span>{txt1} <span style={{ color: disOrWit ? AppColors.errorColor : 'inherit' }}>{txt2}</span><Padding /></span>;
        const golferOrTeam = individual ? 'Golfer' : 'Team';
        const withdrawnStatus = withdrawn ? golferOrTeam + ' withdrawn' : disqualified ? golferOrTeam + ' disqualified' : undefined;
        return <>
            <div className={classes.editscoreScrollPanel}>
                <div className={scrollStyle}>
                    <ScorecardRow
                        inOutForm={inOut}
                        label="Hole"
                        holesType={eventOrRound.holesType}
                        gen={holeLabelsGen}
                        classes={{ row: classes.holeRow }} />
                    {(len && !!len.find(l => !!l)) && <ScorecardRow
                        inOutForm={inOut}
                        label="Length"
                        backgroundColor={usedTee?.name}
                        holesType={eventOrRound.holesType}
                        gen={lenLabelsGen}
                        classes={{ row: classes.parRow }} />}
                    {par && <ScorecardRow
                        inOutForm={inOut}
                        label="Par"
                        holesType={eventOrRound.holesType}
                        gen={parLabelsGen}
                        classes={{ row: classes.parRow }} />}
                    {isNet && displayHCP && <ScorecardRow
                        inOutForm={inOut}
                        label="HCP"
                        holesType={eventOrRound.holesType}
                        gen={displayHCP}
                        classes={{ row: classes.hcpRow }} />}
                    {bestBall && contacts.map((golfer, gi) => <ScorecardRow
                        inOutForm={inOut}
                        key={golfer.id}
                        label={shortName(golfer)}
                        holesType={eventOrRound.holesType}
                        classes={readonly ? { row: classes.hcpGross } : { row: classes.hcpGross, cells: classes.hcpGrossCells }}
                        rightBoarderDots={isNet ? Math.max(getHoleHandicap(8, eventOrRound.holesType, tees[gi], eventOrRound.handicapSystem, getGolferRangeHandicap(eventOrRound.holesType, eventOrRound.handicapSystem, golfer.handicapIndex), getHandicapsAllowance(golfer, scoring, gi)) || 0,
                            getHoleHandicap(17, eventOrRound.holesType, tees[gi], eventOrRound.handicapSystem, getGolferRangeHandicap(eventOrRound.holesType, eventOrRound.handicapSystem, golfer.handicapIndex), getHandicapsAllowance(golfer, scoring, gi)) || 0) : 0}
                        gen={hole => bestBallGen(hole, golfer, gi)} />)}
                    <ScorecardRow
                        inOutForm={inOut}
                        label={titleGross}
                        holesType={eventOrRound.holesType}
                        classes={readonlyTeam ? { row: classes.hcpGross } : { row: classes.hcpGross, cells: classes.hcpGrossCells }}
                        rightBoarderDots={isNet ? Math.max(getHoleHandicap(8, eventOrRound.holesType, usedTee, eventOrRound.handicapSystem, getGolferRangeHandicap(eventOrRound.holesType, eventOrRound.handicapSystem, contactInfo.handicapIndex), getHandicapsAllowance(contactInfo, scoring, 0), playingHandicap) || 0,
                            getHoleHandicap(17, eventOrRound.holesType, usedTee, eventOrRound.handicapSystem, getGolferRangeHandicap(eventOrRound.holesType, eventOrRound.handicapSystem, contactInfo.handicapIndex), getHandicapsAllowance(contactInfo, scoring, 0), playingHandicap) || 0) : 0}
                        gen={grossScoresGen} />
                    {isNet && bestBall && <ScorecardRow
                        inOutForm={inOut}
                        label={titleTotalNet}
                        holesType={eventOrRound.holesType}
                        classes={{ row: classes.hcpNet }}
                        gen={netBestballScoresGen} />}
                    {isNet && !bestBall && <ScorecardRow
                        inOutForm={inOut}
                        label={titleTotalNet}
                        holesType={eventOrRound.holesType}
                        classes={{ row: classes.hcpNet }}
                        gen={netScoresGen} />}
                    {isStableford && isGross && <ScorecardRow
                        inOutForm={inOut}
                        label={'POINTS'}
                        holesType={eventOrRound.holesType}
                        classes={{ row: classes.editscoreStableford }}
                        gen={stablefordScoresGen} />}
                    {isStableford && isNet && <ScorecardRow
                        inOutForm={inOut}
                        label={'POINTS NET'}
                        holesType={eventOrRound.holesType}
                        classes={{ row: classes.editscoreStableford }}
                        gen={stablefordNetScoresGen} />}
                    {isSkins && isGross && skinsGross && <ScorecardRow
                        inOutForm={inOut}
                        label={isNet && isGross ? 'SKINS GROSS' : 'SKINS'}
                        holesType={eventOrRound.holesType}
                        classes={{ row: classes.hcpNet }}
                        gen={getSkinsGen(skinsGross)} />}
                    {isSkins && isNet && skinsNet && <ScorecardRow
                        inOutForm={inOut}
                        label={isNet && isGross ? 'SKINS NET' : 'SKINS'}
                        holesType={eventOrRound.holesType}
                        classes={{ row: classes.hcpNet }}
                        gen={getSkinsGen(skinsNet)} />}
                </div>
            </div>
            <div className={classes.editscoreTotal}>
                {<div className={classes.editscoreTotal}>
                    <div style={{ float: 'left' }}>
                        {!readonly && isReported && <FormControlLabel
                            style={{ marginLeft: 0 }}
                            label={'Mark all golfer-posted scores verified'}
                            control={<Checkbox color="secondary" checked={markVerified} disabled={readonly} onChange={(e, v) => { e.stopPropagation(); this.handleMarkChange(v); }} />}
                        />}
                        {!readonly && pickUpImage &&
                            <InfoElement rightIcon iconStyle={{ transform: 'scale(0.78)' }}
                                alertMsg={'Organizer can enter "#" on a hole where a golfer or team has picked up their ball. ' + 'All pick-ups in Events will be recognized as net double bogey.'}>
                                <div className={classes.pickupsMsg}>Enter # for golfer pick up</div>
                            </InfoElement>}
                        {withPickUpInfo && pickUpImage &&
                            <div className={classes.pickupsMsg}>
                                <img style={{ marginBottom: -4 }} src={pickUpImage} alt="" />
                                = Pick up
                                <IconButton
                                    size="large"
                                    onClick={() => showAlert('Pick-ups in Events will be recognized as net double bogey.')}>
                                    <InfoIcon style={{ transform: 'scale(0.78)' }} />
                                </IconButton>
                            </div>}
                        {(!readonly && isReported) && <br />}
                        {withdrawnStatus && <span style={{ color: AppColors.errorColor }}>{withdrawnStatus}</span>}
                    </div>
                </div>}
                <div style={{ float: 'right' }}>
                    {textElem('Total GROSS:', grossValue)}
                    {isNet && textElem('NET:', netValue)}
                </div>
                <div style={{ float: 'right', whiteSpace: "nowrap" }}>
                    {isStableford && isGross && textElem('Total POINTS:', totPoints)}
                    {isStableford && isNet && textElem('Total NET POINTS:', totNetPoints)}
                </div>
                {mainCompetition?.scoring?.format === ScoringFormatTeams.best_ball && competition?.scoring?.format === ScoringFormatSkins.skins_team &&
                    <Typography className={classes.pickupsMsg}>Enter scores using existing Best Ball competition</Typography>}
            </div>
            {(withdrawn || disqualified) && <br />}
        </>;
    }

    private handleSendMessage = async (contactsToSend: Contact[], variant: EmailVariant) => {
        const { eventData } = this.props;
        if (eventData) {
            await sendPaymentMessage(contactsToSend, eventData, new Map<string, Score>(), new Map<string, Score>(),
                new Map<string, ReportedScore>(), new Map<string, ReportedScore>(), new Map<string, Distance>(), variant);
        }
    };

    render() {
        const { open, classes, eventOrRound, player, contactInfo, teeTime, readonly, competition, golfers, handleContactChanged, userAware, mainCompetition, roundScorecardLink } = this.props;
        const { scores, changed, confirmingCancel, withdrawn, disqualified, editedContact, contacts } = this.state;
        if (!player.id) {
            return null;
        }
        const scoring = competition.scoring;
        const bestBall = scoring.format === 'best_ball';
        const isNet = isNetMode(scoring.mode);
        const tees = contacts.map(c => getTee(eventOrRound, competition, c.gender, c));
        const usedTee = hardestTee(tees);
        const { handicap } = usedTee || {} as Tee;
        const totalHoles = this.holesRange.last - this.holesRange.first;
        const inOut = totalHoles === 18;
        const showReported = !!userAware?.workingUserId;
        let hasScores = true;
        if (bestBall) {
            for (let i = 0; i < contacts.length; i++) {
                if (!scores.has(contacts[i].id)) {
                    hasScores = false;
                }
            }
        }
        if (scores.size === 0) {
            hasScores = false;
        }
        let playingHandicap = 0;
        if (isTeamScoringExceptBB(scoring) || (scoring.format === ScoringFormatSkins.skins_team && mainCompetition?.scoring?.format && mainCompetition.scoring.format !== ScoringFormatTeams.best_ball)) {
            playingHandicap = contactInfo.teamPlayingHandicap ?? 0;
        }
        else if (!bestBall && isNet) {
            const contact = contactInfo.contacts[0];
            playingHandicap = getGolferPlayingHandicap(contact, eventOrRound, competition);
        }
        let isReported = false;
        if (showReported && hasScores) {
            const score = this.scoresOf(player.id);
            const reportedScore = this.reportedScoresOf(player.id);
            isReported = isReported || Scoring.markAsReported(score, reportedScore);
        }
        const courseHandicaps = bestBall ?
            'Playing handicaps: ' + contacts.map((g, idx) => {
                return formatHandicap(getGolferPlayingHandicap(g, eventOrRound, competition, idx));
            }).join(', ') :
            isNet ? 'Playing handicap: ' + formatHandicap(playingHandicap) : '';
        const noTee = !usedTee ? ' (Tees not selected)' : '';
        const dialogStyle = inOut ? (totalHoles === 18 ? classes.dialogRoot20 : classes.dialogRoot10) : (totalHoles === 18 ? classes.dialogRoot18 : classes.dialogRoot9);
        const narrowScreen = window.screen.width < 390;
        const wdOrDqLabel = withdrawn ? (narrowScreen ? 'WDwn' : 'Withdrawn') :
            (disqualified ? narrowScreen ? 'DQed' : 'Disqualified' :
                narrowScreen ? 'WD/DQ' : 'Withdraw / DQ');
        const editIcon = () => {
            return <IconButton size="small" color="primary"><EditIcon style={{ fontSize: '1em' }} /></IconButton>;
        };
        const sameNameGolfersIdsSet: Set<string> = getSameNameGolfersIds(Array.from(golfers.values()));
        const headTitle = (
            <div>
                <div style={{ float: 'left' }}>
                    {contacts.map(contact => (
                        <span key={contact.id} className={classes.editscoreContacts} onClick={() => this.editPlayer(contact)} style={{ cursor: 'pointer' }}>
                            <Typography className={classes.editscoreNames}>{fullName(contact)}<span className={classes.homeCourseOrCity}>
                                {contact.homeCourseOrCity && sameNameGolfersIdsSet.has(contact.id) ? ` (${contact.homeCourseOrCity})` : ''}</span>
                            </Typography>
                            <Typography className={classes.editscoreHandicaps}>
                                {handicap ? ' (' + formatHandicap(getGolferRangeHandicap(eventOrRound.holesType, eventOrRound.handicapSystem, contact.handicapIndex)) + ')' : ''}
                                {contact.hidden ? ' (deleted)' : ''}
                                {handleContactChanged && editIcon()}
                            </Typography>
                        </span>
                    ))}
                </div>
                {isReported && <div style={{ float: 'right' }}>
                    <Typography variant="caption" className={classes.marginLeftAuto}><span className={classes.legendIconGreen} />
                        <div style={{ display: 'inline', marginTop: '-5px', fontSize: '0.875rem' }}> Golfer-posted scores<IconButton size='small' onClick={this.showPlayerScoresInfo}>
                            <InfoIcon style={{ fontSize: '1em' }} /></IconButton>
                        </div>
                    </Typography>
                </div>}
                <div style={{ clear: 'both' }} />
            </div>
        );
        return (
            <SMMobileDialog open={open} onClose={this.handleClose}
                onKeyDown={uiEvent => processEnterKey(uiEvent, this.handleSave)}
                maxWidth={'md'} classes={{ paper: dialogStyle }}>
                <NavigationBlocker when={!!this.state.changed} />
                <DialogAppBar label={readonly ? 'Scorecard' : 'Edit scorecard'} close={this.handleClose} />
                <DialogContent classes={{ root: classes.dialogContent }}>
                    <div className={classes.editscoreHeader}>
                        {headTitle}
                        <Typography className={classes.editscoreSubhead}>
                            {eventOrRound.type !== 'leaderboard' && <>
                                {teeTime ? teeTimeName(teeTime) : ''}
                                <Padding />
                            </>}
                            {teeName(usedTee || undefined)}
                            <Padding />
                            {courseHandicaps + noTee}
                        </Typography>
                        {roundScorecardLink && <div className={classes.flexRowContainer}>
                            <div className={classes.roundScorecardLinkIconDiv}><img className={classes.roundScorecardLinkIcon} src={linkIconImage} alt='' /></div>
                            <a className={`${classes.linkBluePointer} ${classes.roundScorecardLink}`} target={'_blank'} rel="noopener noreferrer" href={roundScorecardLink}>Golf Pad Web Scorecard</a>
                        </div>}
                    </div>
                    {hasScores && this.renderScores({ tees, usedTee, playingHandicap, isReported })}
                </DialogContent>
                {!readonly && <DialogActions className={classes.editscoreActions} style={{ marginTop: '0px' }}>
                    <AppButton onClick={this.handleClear} color="info">Clear</AppButton>
                    <AppButton color="info" onClick={this.handleWDorDQClick}>
                        {wdOrDqLabel}<DropDownArrowIcon className={classes.rightButtonIcon} />
                    </AppButton>
                    <this.WDorDQMenu />
                    <AppButton onClick={this.handleClose} color="info">Cancel</AppButton>
                    <AppButton onClick={this.handleSave} color="secondary" disabled={!changed && !isReported}>Save</AppButton>
                </DialogActions>}
                {readonly && <DialogActions className={classes.editscoreActions} style={{ marginTop: '0px' }}>
                    <div />
                    <div />
                    <AppButton onClick={this.handleClose} color="secondary">Done</AppButton>
                </DialogActions>}
                {!!confirmingCancel && <ConfirmDialog
                    open
                    disableEscapeKeyDown
                    disableBackdropClick
                    onOk={this.props.close}
                    onCancel={confirmingCancel}
                    title="Confirm exit"
                    content="Discard all changes?"
                    cancelLabel="Cancel"
                    okLabel="Discard and exit" />}
                {!!editedContact && <EditContactDialog
                    open
                    event={eventOrRound}
                    rounds={this.props.eventData?.rounds ?? []}
                    actionMode="edit"
                    hasPro={!!userAware?.hasPro}
                    initialContact={editedContact}
                    saveToEvent={this.onContactChanged}
                    sendEmail={this.handleSendMessage}
                    deleteFromEvent={this.onContactDeleted}
                    handleClose={() => this.setState({ editedContact: undefined })} />}
                <this.scoreDocs />
            </SMMobileDialog>
        );
    }
}

class EditScoreDialog extends React.Component<EditScoreProps> {

    private handleContactChanged = (contactDetails: ContactDetails) => {
        const { eventOrRound } = this.props;
        saveContact(eventOrRound, contactDetails, 'Golfer modified').catch(err => errMsg(err));
    }

    private handleContactsDeleted = (contacts: Array<Contact>) => {
        const reportedGolfers = contacts.filter(contact => !!contact.reportedBy);
        if (reportedGolfers.length === 0) {
            this.deleteGolfers(contacts);
        } else {
            showReportedGolfersDeletionAlert(reportedGolfers, () => this.deleteGolfers(contacts));
        }
    }

    private deleteGolfers = (contacts: Array<Contact>) => {
        const { eventRoot } = this.props;
        withProgress(deleteGolfersFromEvent(eventRoot, contacts, true));
    }

    render() {
        const { ...props } = this.props;
        return <ScoreDialog
            {...props}
            handleContactChanged={this.handleContactChanged}
            handleContactDeleted={this.handleContactsDeleted}
        />;
    }
}

export default function EditScoreDialogFC(props: Omit<EditScoreProps, 'classes' | 'userAware'>) {
    const classes = useAppStyles();
    const userAware = useUserAware();
    return <EditScoreDialog classes={classes} userAware={userAware} {...props} />;
}
//export default withStyles(styles)(withUserId(EditScoreDialog));

export function PortalEditScoreDialog(props: Omit<EditScoreProps, 'classes' | 'userAware'>) {
    const classes = useAppStyles();
    const userAware = useUserAware();
    return <ScoreDialog classes={classes} userAware={userAware} {...props} />;
}
//export  PortalEditScoreDialog = withStyles(styles)(ScoreDialog);
