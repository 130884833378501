import * as React from 'react';
import {
    Typography, DialogActions, DialogContent, InputLabel, IconButton, Checkbox, Select, MenuItem,
    FormControlLabel, FormHelperText, FormControl, MenuProps, useMediaQuery, useTheme
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import {
    Competition, Contact, ScoringMode, ScoringFormat, Team, GolferGroup, ScoringFormatIndividual, ScoringFormatTeams, ScoringFormatDistance, ScoringFormatSkins, PayoutSettings,
    isMainCompetitionScoring, isDistanceScoring, isSkinsScoring, isTeamScoring, isIndividualScoring, isTeamFormat, isNetMode, isGrossMode, isCompatibleScores, getTee, getParticipantsCount, getPayoutSettings,
    getEventMainCompetition, isCompatibleCompetition, PayoutMethod, isStablefordScoring, ScoringType, isTeamFormatExceptBB, appScoringStarted, ScoringTeamSize, Event, EventBase
} from '../../../types/EventTypes';
import { getHolesName, initHandicaps, copyCompetition, genderFromEvent, isDuplicatedCompetition } from '../../Event';
import { showAlert, replaceUrl, NoticeElement, InfoElement } from '../../../redux/ReduxConfig';
import { SMMobileDialog } from '../../../common/dialog/MobileDialog';
import { SettingsIcon, InfoIcon } from '../../../common/Icons';
import DialogAppBar from '../../../common/dialog/DialogAppBar';
import AppButton from '../../../common/components/AppButton';
import TypedFormRadioLabel from '../../../common/form/TypedFormRadioLabel';
import SelectHolesDialog from '../../tabs/common/SelectHolesDialog';
import { equalArrays, makeFriendlyString, withS } from '../../../util/utility';
import { ListElem, Container, Item, Spacing, Flex } from '../../../common/Misc';
import { styles, useAppStyles } from '../../../styles';
import * as Scoring from '../../../scoring/scoring';
import ParticipantsDialog from './ParticipantsDialog';
import StablefordPointSettingsDialog from './StablefordPointSettingsDialog';
import CompetitionPayoutSettingsDialog, { getParticipantsName, updatePayouts } from './CompetitionPayoutSettingsDialog';
import CompetitionFlightsSettingsDialog from './CompetitionFlightsSettingsDialog';
import CompetitionNameDialog from './CompetitionNameDialog';
import * as Str from 'src/util/strings';
import { AppColors } from 'src/main/Theme';
import ProNotice from 'src/common/ProNotice';
import { WithUserAware, useUserAware } from 'src/auth/Auth';

const listItemSymbolComponent = <span style={{ fontSize: "1.1rem" }}>•</span>;
const skinsPayoutExplanation = <span>{listItemSymbolComponent}&nbsp;{Str.carryoversUncheckedExplanation}<br />{listItemSymbolComponent}&nbsp;{Str.carryoversCheckedExplanation}</span>;
const competitionAddNotice = 'This competition will be added to all rounds of the event.'
const competitionChangeNotice = 'Changes will be made to all rounds of the event.'

const PERCENTS = function() {
    const percents: Array<number> = [];
    for (let i = 0; i <= 100; i += 5) {
        percents.push(i);
        if (i === 30) {
            percents.push(33);
        }
        if (i === 90) {
            percents.push(93);
        }
    }
    return percents.reverse();
}();

const menuProps: Partial<MenuProps> = {
    style: {
        marginTop: 10
    },
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "left"
    },
    transformOrigin: {
        vertical: "top",
        horizontal: "left"
    },
    PaperProps: {
        style: {
            height: "185px",
            overflowY: "scroll"
        }
    }
};

function getScoringLabel(text: string, team?: boolean) {
    if (!team && text === 'skins_individual') {
        text = 'skins';
    }
    return makeFriendlyString(text, true);
}

export function HandicapPercentSelector(props: { value: number, index: number, handlePercentsChange: (num: number, val: number) => void, individual?: boolean, teamSize?: ScoringTeamSize }) {
    const { value, index, handlePercentsChange, individual, teamSize } = props;
    const ABC = ['A', 'B', 'C', 'D'];
    const labelId = 'select-hcp-percent-' + index;
    const label = individual ? (teamSize ? 'Handicap Allowance' : 'Use') : (ABC[index]) + ' player';
    const isXs = useMediaQuery(useTheme().breakpoints.down('sm'));
    const minWidth = teamSize ? (teamSize > 1 ? 73 : isXs ? 150 : 200) : 80;
    const margin = teamSize ? `8px ${index === teamSize - 1 ? 0 : 10}px 8px 0` : 8;
    return (
        <FormControl variant="standard" style={{ minWidth, margin }}>
            <InputLabel shrink id={labelId}>
                {label}
            </InputLabel>
            <Select
                variant="standard"
                MenuProps={menuProps}
                labelId={labelId}
                id={labelId + 'menu'}
                value={value}
                onChange={uiEvent => handlePercentsChange(index, parseInt(uiEvent.target.value as string, 10))}>
                {PERCENTS.map(p => <MenuItem key={p} value={p}>{p + '%'}</MenuItem>)}
            </Select>
            {individual && <FormHelperText>of course handicap</FormHelperText>}
        </FormControl>
    );
}

type Props = {
    open: boolean;
    allRounds: boolean;
    event: Event;
    eventOrRound: EventBase;
    competition: Competition;
    competitions: Array<Competition>;
    golfers: Map<string, Contact>;
    groups: Array<GolferGroup>;
    teams: Map<string, Team>;
    hasScores: boolean;
    isNewCompetition?: boolean;
    handleSave: (competition: Competition, competitionOld: Competition, resetScores: boolean, resetDistance: boolean, changes: Map<string, string>, allRounds: boolean) => void;
    handleClose: () => void;
    handleDelete?: (competition: Competition, allRounds: boolean) => void;
    mainCompetition?: Competition;
} & WithStyles<typeof styles> & WithUserAware;

type State = {
    competition: Competition;
    selectingParticipants?: boolean;
    editPayout?: PayoutSettings;
    editPayoutMode?: string;
    editFlights?: boolean;
    selectingHoles?: boolean;
    selectingTees?: boolean;
    showPointSettings?: boolean;
    selectingName?: boolean;
    changes: Map<string, string>;
};

class CompetitionSettingsDialog extends React.Component<Props, State> {
    state: State = {
        competition: copyCompetition(this.props.competition),
        changes: new Map()
    };

    private handleSelectHoles = () => this.setState({ selectingHoles: true });
    private handleHolesCancel = () => this.setState({ selectingHoles: false });

    private handleNetPayoutChange = (checked: boolean, setUndefined: boolean) => {
        const { competition, changes } = this.state;
        if (checked) {
            if (!competition.payoutsNet[0]) {
                competition.payoutsNet[0] = {
                    enabled: true,
                    purseType: isSkinsScoring(competition.scoring) ? 'valuePerSkin' : 'fixed',
                    payoutMethod: 'divided'
                } as PayoutSettings;
            } else {
                competition.payoutsNet[0].enabled = true;
            }
        } else {
            if (competition.payoutsNet[0]) {
                competition.payoutsNet[0].enabled = false;
            }
        }
        changes.set('payouts net', checked ? 'enabled' : 'disabled');
        this.setState({ competition, editPayoutMode: setUndefined ? undefined : 'net' });
    };

    private handleGrossPayoutChange = (checked: boolean, setUndefined: boolean) => {
        const { competition, changes } = this.state;
        if (checked) {
            if (!competition.payoutsGross[0]) {
                competition.payoutsGross[0] = {
                    enabled: true,
                    purseType: isSkinsScoring(competition.scoring) ? 'valuePerSkin' : 'fixed',
                    payoutMethod: 'divided'
                } as PayoutSettings;
            } else {
                competition.payoutsGross[0].enabled = true;
            }
        } else {
            if (competition.payoutsGross[0]) {
                competition.payoutsGross[0].enabled = false;
            }
        }
        changes.set('payouts gross', checked ? 'enabled' : 'disabled');
        this.setState({ competition, editPayoutMode: setUndefined ? undefined : 'gross' });
    };

    private handleFlightsChange = (checked: boolean) => {
        const { competition } = this.state;
        if (!checked) {
            competition.flights = undefined;
        }
        this.setState({ competition, editFlights: checked });
    }

    private handleDeleteFlights = () => {
        const { competition } = this.state;
        competition.flights = undefined;
        this.setState({ competition, editFlights: false });
    }

    private handleHandicapChange = (val: string) => {
        const { eventOrRound } = this.props;
        const { competition, changes } = this.state;
        competition.scoring.mode = val as ScoringMode;
        changes.set('mode', val);
        initHandicaps(competition, eventOrRound.teamSize);
        this.setState({ competition });
    }

    private handleHolesOk = (selectedHoles: Array<number>) => {
        const { competition, changes } = this.state;
        const { golfers, teams } = this.props;
        if (competition.scoring.holes !== selectedHoles && (isDistanceScoring(competition.scoring) || isSkinsScoring(competition.scoring)) && (competition.payoutsGross[0] || competition.payoutsNet[0])) {
            if (competition.payoutsGross[0]) {
                updatePayouts(competition.payoutsGross[0], getParticipantsCount(competition, golfers, teams), selectedHoles.length);
            }
            if (competition.payoutsNet[0]) {
                updatePayouts(competition.payoutsNet[0], getParticipantsCount(competition, golfers, teams), selectedHoles.length);
            }
            changes.set('holes', 'changed');
        }
        competition.scoring.holes = selectedHoles;
        this.setState({ competition, selectingHoles: false, changes });
    }

    private handleFormatChange = (uiEvent: any) => {
        const { eventOrRound } = this.props;
        if (!uiEvent.target.value) {
            return;
        }
        const { competition, changes } = this.state;
        competition.scoring.format = uiEvent.target.value as ScoringFormat;
        delete competition.scoring.holes;
        changes.set('format', uiEvent.target.value);
        initHandicaps(competition, eventOrRound.teamSize);
        this.setState({ competition });
    }

    private handleHandicapPercentsChange = (num: number, val: number) => {
        const { eventOrRound } = this.props;
        const { competition, changes } = this.state;
        if (competition.scoring.handicaps![num] !== val) {
            changes.set('handicapPercents' + num, String(val));
            initHandicaps(competition, eventOrRound.teamSize);
            competition.scoring.handicaps![num] = val;
            this.setState({ competition });
        }
    }

    private handleScoringModeChanged = (val: ScoringType) => {
        const { competition } = this.state;
        if (val === ScoringFormatIndividual.strokeplay && competition.scoring.stablefordMode) {
            competition.scoring.stablefordMode = false;
            this.setState({ competition });
        } else if (val === ScoringFormatIndividual.stableford && !competition.scoring.stablefordMode) {
            competition.scoring.stablefordMode = true;
            this.setState({ competition });
        }
    }

    private setCompetitionName = (name: string) => {
        const { competition } = this.state;
        competition.name = name;
        this.setState({ selectingName: false, competition });
    }

    private handleSave = () => {
        const { competition: competitionOld, allRounds, eventOrRound, hasScores, isNewCompetition, teams, golfers, competitions, handleSave, mainCompetition } = this.props;
        const { competition, changes } = this.state;
        const isDistance = isDistanceScoring(competitionOld.scoring);
        if (competitionOld.id && hasScores && !isCompatibleScores(competitionOld.scoring, competition.scoring, mainCompetition)) {
            showAlert('Some scores were entered using the existing format and can\'t be used for the new format. This will reset the existing scores.', [
                { title: 'Proceed', action: () => handleSave(competition, competitionOld, !isDistance, isDistance, changes, allRounds) },
                { title: 'Cancel' }
            ]);
        } else if (isNewCompetition && isDuplicatedCompetition(competition, competitions)) {
            showAlert('It appears this is a duplicate competition. Do you want to add a new competition, with the same settings?', [
                { title: 'Cancel' },
                {
                    title: 'Proceed',
                    color: 'secondary',
                    action: () => handleSave(competition, competitionOld, false, false, changes, allRounds)
                }
            ]);
        } else if (appScoringStarted(golfers, teams, eventOrRound.appCompetition, mainCompetition) && (
            (competitionOld.scoring.format === ScoringFormatTeams.best_ball && isTeamFormatExceptBB(competition.scoring, mainCompetition)) ||
            (competition.scoring.format === ScoringFormatTeams.best_ball && isTeamFormatExceptBB(competitionOld.scoring, mainCompetition)))) {
            showAlert('Event round has been already started for some golfers. Changing competition format will effect the scores posting ability from the Golf Pad app round.' +
                ' Are you sure you want to save new competition format?',
                [
                    { title: 'Cancel' },
                    { title: 'Proceed', color: 'secondary', action: () => handleSave(competition, competitionOld, false, false, changes, allRounds) },
                ]);
        } else {
            handleSave(competition, competitionOld, false, false, changes, allRounds);
        }
    }

    private onParticipantsChanged = (competition: Competition) => {
        const { changes } = this.state;
        changes.set('participants', (competition.everyone ? 'All ' : competition.contactIds ? competition.contactIds.length : 0) + (competition.competitionGender && competition.everyone && competition.competitionGender !== 'both' ? competition.competitionGender : ''));
        this.setState({ competition, selectingParticipants: false });
    }

    private onFlightsChanged = (competition: Competition) => {
        const { changes } = this.state;
        changes.set('flights', String(competition.flights));
        this.setState({ competition, editFlights: false });
    }

    private onPayoutsChanged = (payoutSettings: PayoutSettings) => {
        const { competition, editPayoutMode, changes } = this.state;
        if (editPayoutMode === undefined) {
            competition.payoutsNet[0] = payoutSettings;
            competition.payoutsGross[0] = payoutSettings;
        } else if (editPayoutMode === 'gross') {
            competition.payoutsGross[0] = payoutSettings;
        } else if (editPayoutMode === 'net') {
            competition.payoutsNet[0] = payoutSettings;
        }
        changes.set('payouts', 'changed');
        this.setState({ competition, editPayout: undefined, changes: changes });
    }

    private onPayoutMethodChange = (allPayoutSettings: PayoutSettings[], payoutMethod: PayoutMethod) => {
        const { competition } = this.state;
        for (const payoutSettings of allPayoutSettings) {
            payoutSettings.payoutMethod = payoutMethod;
        }
        this.setState({ competition });
    }

    private setNewCompetitionPayouts = (competition: Competition, net: boolean, isSkins: boolean) => {
        if (net) {
            competition.payoutsNet[0] = { enabled: false, purseType: isSkins ? 'valuePerSkin' : 'fixed', payoutMethod: 'divided' } as PayoutSettings;
            return competition.payoutsNet[0];
        } else {
            competition.payoutsGross[0] = { enabled: false, purseType: isSkins ? 'valuePerSkin' : 'fixed', payoutMethod: 'divided' } as PayoutSettings;
            return competition.payoutsGross[0];
        }
    };

    private getActualPayoutSettings = (competition: Competition) => {
        const isNet = isNetMode(competition.scoring.mode);
        const isGross = isGrossMode(competition.scoring.mode);
        const isSkins = isSkinsScoring(competition.scoring);
        if (isNet && isGross) {
            return [
                competition.payoutsNet[0] ?? this.setNewCompetitionPayouts(competition, true, isSkins),
                competition.payoutsGross[0] ?? this.setNewCompetitionPayouts(competition, false, isSkins)
            ];
        } else {
            return [(isNet) ? competition.payoutsNet[0] ?? this.setNewCompetitionPayouts(competition, true, isSkins)
                : (isGross) ? competition.payoutsGross[0] ?? this.setNewCompetitionPayouts(competition, false, isSkins)
                    : { enabled: false, purseType: isSkins ? 'valuePerSkin' : 'fixed', payoutMethod: 'divided' } as PayoutSettings];
        }
    };

    private savePoints = (stablefordPoints: Array<number>) => {
        const { competition } = this.state;
        competition.scoring.mstablefordPoints = stablefordPoints;
        if (competition.scoring.format === ScoringFormatIndividual.stableford && stablefordPoints?.length &&
            !equalArrays(stablefordPoints, Scoring.defaultStablefordPoints)) {
            competition.scoring.format = ScoringFormatIndividual.modified_stableford;
        }
        this.setState({ showPointSettings: false, competition });
    };

    private SkinsAwarded = () => {
        const { classes } = this.props;
        const { competition } = this.state;
        const actualPayoutSettings: PayoutSettings[] = this.getActualPayoutSettings(competition);
        if (actualPayoutSettings.length < 1) {
            return null;
        }
        return (
            <div>
                <FormControl variant="standard">
                    <InputLabel shrink style={{ marginTop: 5 }}>
                        Skins awarded <NoticeElement>{skinsPayoutExplanation}</NoticeElement>
                    </InputLabel>
                    <FormControlLabel className={classes.formSelector} control={<Container style={{ marginTop: 16 }}>
                        <Item><Checkbox color="secondary" className={classes.marginBottom3} onChange={(e, checked) => {
                            e.stopPropagation();
                            this.onPayoutMethodChange(actualPayoutSettings, checked ? 'carryovers' : 'divided');
                        }} checked={actualPayoutSettings[0].payoutMethod === 'carryovers'} /></Item>
                        <Item><ListElem title={'Carryover tied holes'} subtitle="" /></Item>
                    </Container>} label="" />
                </FormControl>
            </div>
        );
    };

    private Holes = () => {
        const { eventOrRound, mainCompetition } = this.props;
        const { competition } = this.state;
        const gender = genderFromEvent(eventOrRound);
        if (!mainCompetition) {
            return null;
        }
        return <>
            <FormControl
                variant="standard"
                margin="dense"
                fullWidth
                style={{ flexDirection: 'row' }}>
                <InputLabel shrink>Holes</InputLabel>
            </FormControl>
            <InputLabel shrink style={{ display: 'unset' }}>{getHolesName(eventOrRound, competition, getTee(eventOrRound, mainCompetition, gender))}&nbsp;&nbsp;&nbsp;
                <AppButton color="info" onClick={this.handleSelectHoles}>Select</AppButton>
            </InputLabel>
        </>;
    }

    private Participants = () => {
        const { classes, eventOrRound, golfers, teams, userAware } = this.props;
        const { competition, selectingParticipants, editFlights } = this.state;
        const participantsCount = getParticipantsCount(competition, golfers, teams);
        const golfersLabel = getParticipantsName(isTeamFormat(competition.scoring), participantsCount);
        const participants =
            !competition.everyone ? golfersLabel :
                competition.competitionGender === 'men' ? `All men (${golfersLabel})` :
                    competition.competitionGender === 'women' ? `All women (${golfersLabel})` :
                        `Everyone (${golfersLabel})`;
        const flights = withS(competition.flights || 1, 'flight');
        const isMain = isTeamScoring(competition.scoring) || isIndividualScoring(competition.scoring);
        const allHandicapIndexesPresent = competition.scoring.mode === 'gross' ? true : Array.from(golfers.values()).every(contact => contact.handicapIndex != null);
        return <>
            <FormControl
                variant="standard"
                margin="dense"
                fullWidth
                style={{ flexDirection: 'row' }}>
                <InputLabel shrink>Participants</InputLabel>
                <FormControlLabel className={classes.formSelector} control={<Container>
                    <Item><ListElem title={participants} subtitle="" /></Item>
                    <Item><AppButton style={{ marginLeft: '1em' }} color="info" onClick={() => this.setState({ selectingParticipants: true })}>Change</AppButton></Item>
                </Container>} label="" />
            </FormControl>
            {!allHandicapIndexesPresent && <FormControl variant="standard" margin="dense">
                <FormControlLabel className={classes.missingHandicapsContainer}
                    label=""
                    control={<Container>
                        <Item>
                            <Typography className={classes.missingHandicapsBase + ' ' + classes.missingHandicapsAlert}>
                                Some golfers are missing handicap for this net competition
                            </Typography>
                        </Item>
                        <Item>
                            <Typography className={`${classes.linkBluePointer} ${classes.missingHandicapsBase} ${classes.missingHandicapsEdit}`}
                                onClick={() => replaceUrl(`/events/${eventOrRound.id}/golfers/list`)}>Edit</Typography>
                        </Item>
                    </Container>}
                />
            </FormControl>}
            {isMain && <FormControl
                fullWidth
                margin="dense"
                variant="standard"
                disabled={!userAware.hasPro}
                style={{ marginTop: -15, flexDirection: 'row' }}>
                <FormControlLabel className={classes.formSelector}
                    label=""
                    control={<Container>
                        <Item><Checkbox color="secondary" className={classes.marginBottom3} onChange={(e, v) => { e.stopPropagation(); this.handleFlightsChange(v); }} checked={!!competition.flights || false} /></Item>
                        <Item><ListElem title='Split into flights by handicap index' subtitle="" /></Item>
                        <Item marginLeft={4}><NoticeElement>{Str.flightExplanation}</NoticeElement></Item>
                    </Container>}
                />
            </FormControl>}
            {!userAware.hasPro &&
                <ProNotice>
                    To create a competition with flights, upgrade to the Events Pro
                </ProNotice>}
            {isMain && !!competition.flights && <FormControl
                fullWidth
                margin="dense"
                variant="standard"
                style={{ marginTop: -15, marginBottom: 10, flexDirection: 'row' }}>
                <FormControlLabel className={classes.formSelector}
                    label=""
                    control={<Container>
                        <Item><ListElem title={flights} subtitle="" /></Item>
                        <Item><AppButton style={{ marginLeft: '1em' }} color="info" onClick={() => this.setState({ editFlights: true })}>Edit</AppButton></Item>
                    </Container>}
                />
            </FormControl>}
            {editFlights && <CompetitionFlightsSettingsDialog
                open
                teams={teams}
                golfers={golfers}
                event={eventOrRound}
                competition={competition}
                handleDelete={this.handleDeleteFlights}
                handleSave={this.onFlightsChanged}
                onClose={() => this.setState({ editFlights: false })} />}
            {selectingParticipants && <ParticipantsDialog
                open
                teams={teams}
                golfers={golfers}
                event={eventOrRound}
                competition={competition}
                handleSave={this.onParticipantsChanged}
                onClose={() => this.setState({ selectingParticipants: false })} />}
        </>;
    }

    private PayoutSettings = () => {
        const { eventOrRound, golfers, teams, classes, userAware } = this.props;
        const { editPayout, competition } = this.state;
        const isNet = isNetMode(competition.scoring.mode);
        const isGross = isGrossMode(competition.scoring.mode);
        const { payoutGrossSettings, payoutNetSettings, payoutSettings } = getPayoutSettings(competition);
        return <>
            <FormControl
                fullWidth
                margin="dense"
                variant="standard"
                disabled={!userAware.hasPro}
                style={{ flexDirection: 'row' }}>
                <InputLabel shrink>Payouts</InputLabel>
                {(!isNet || !isGross) && <FormControlLabel
                    label=""
                    className={classes.formSelector}
                    control={<Container>
                        <Item><Checkbox color="secondary" className={classes.marginBottom3} onChange={(e, v) => { e.stopPropagation(); isGross ? this.handleGrossPayoutChange(v, true) : this.handleNetPayoutChange(v, true); }} checked={!!payoutSettings && payoutSettings.enabled} /></Item>
                        <Item><ListElem title={isNet && isGross ? 'Net payouts' : 'Calculate payouts'} subtitle="" /></Item>
                        <Item><AppButton style={{ marginLeft: '1em' }} disabled={!payoutSettings || !payoutSettings.enabled} color="info" onClick={() => this.setState({ editPayout: payoutSettings, editPayoutMode: undefined })}>Edit</AppButton></Item>
                    </Container>}
                />}
                {isNet && isGross && <FormControlLabel className={classes.formSelector}
                    label=""
                    control={<Container>
                        <Item><Checkbox color="secondary" className={classes.marginBottom3} onChange={(e, v) => { e.stopPropagation(); this.handleGrossPayoutChange(v, false); }} checked={!!payoutGrossSettings && payoutGrossSettings.enabled} /></Item>
                        <Item><ListElem title={isNet && isGross ? 'Gross payouts' : 'Calculate payouts'} subtitle="" /></Item>
                        <Item><AppButton style={{ marginLeft: '1em' }} disabled={!payoutGrossSettings || !payoutGrossSettings.enabled} color="info" onClick={() => this.setState({ editPayout: payoutGrossSettings, editPayoutMode: 'gross' })}>Edit</AppButton></Item>
                    </Container>}
                />}
                {isNet && isGross && <FormControlLabel className={classes.formSelector}
                    label=""
                    control={<Container>
                        <Item><Checkbox color="secondary" className={classes.marginBottom3} onChange={(e, v) => { e.stopPropagation(); this.handleNetPayoutChange(v, false); }} checked={!!payoutNetSettings && payoutNetSettings.enabled} /></Item>
                        <Item><ListElem title={isNet && isGross ? 'Net payouts' : 'Calculate payouts'} subtitle="" /></Item>
                        <Item><AppButton style={{ marginLeft: '1em' }} disabled={!payoutNetSettings || !payoutNetSettings.enabled} color="info" onClick={() => this.setState({ editPayout: payoutNetSettings, editPayoutMode: 'net' })}>Edit</AppButton></Item>
                    </Container>}
                />}
            </FormControl>
            {!userAware.hasPro &&
                <ProNotice>
                    To create a competition with payouts, upgrade to the Events Pro
                </ProNotice>}
            {editPayout && <CompetitionPayoutSettingsDialog
                open
                event={eventOrRound}
                competition={competition}
                participantCount={getParticipantsCount(competition, golfers, teams)}
                isTeam={isTeamScoring(competition.scoring)}
                isSkins={isSkinsScoring(competition.scoring)}
                payoutSettings={editPayout!}
                handleSave={this.onPayoutsChanged}
                onClose={() => this.setState({ editPayout: undefined })} />}
        </>;
    };

    render() {
        const { allRounds, event, eventOrRound, competitions, isNewCompetition, handleDelete, classes, handleClose } = this.props;
        const { competition, selectingHoles, showPointSettings, selectingName } = this.state;
        const isMain = isMainCompetitionScoring(competition.scoring);
        const isSkins = isSkinsScoring(competition.scoring);
        const isDistance = isDistanceScoring(competition.scoring);
        const isTeam = isTeamScoring(competition.scoring);
        const competitionsMain = competitions.filter(comp => isMainCompetitionScoring(comp.scoring));
        const mainCompetition = getEventMainCompetition(competitionsMain);
        const scoringMode = competition.scoring.stablefordMode ? 'stableford' : 'strokeplay';
        const title = isMain ? 'Competition settings' : 'Side game settings';
        let individualFormats: Array<{ format: string, label: string }>;
        let teamFormats: Array<{ format: string, label: string }> = [];
        if (isMain) {
            individualFormats = Object.keys(ScoringFormatIndividual).filter(format => format !== ScoringFormatIndividual.modified_stableford)
                .map(f => { return { format: f, label: getScoringLabel(f) }; });
            teamFormats = Object.keys(ScoringFormatTeams)
                .map(f => { return { format: f, label: getScoringLabel(f) }; });
        } else {
            individualFormats = Object.keys(ScoringFormatDistance).map(f => { return { format: f, label: getScoringLabel(f) }; });
            individualFormats.push({ format: ScoringFormatSkins.skins_individual, label: getScoringLabel(ScoringFormatSkins.skins_individual, eventOrRound.teamSize > 1) });
            if (eventOrRound.teamSize > 1) {
                teamFormats.push({ format: ScoringFormatSkins.skins_team, label: getScoringLabel(ScoringFormatSkins.skins_team) });
            }
        }
        const handicapUserNum = !competition.scoring.handicaps || isDistance || !isNetMode(competition.scoring.mode) ? 0 : isIndividualScoring(competition.scoring) ? 1 : eventOrRound.teamSize;
        const incompatibleIndividuals = !isCompatibleCompetition(competition, competitions, eventOrRound.teamSize);
        const doesntFit = incompatibleIndividuals ||
            (isDistance && (!competition.scoring.holes || competition.scoring.holes.length === 0)) ||
            (isSkins && (competition.scoring.holes && competition.scoring.holes.length === 0));
        const gender = genderFromEvent(eventOrRound);
        const skinsMixed = !!competitions.find(comp => comp.scoring.format === ScoringFormatTeams.best_ball);
        const competitionName = Scoring.scoringName(competition, eventOrRound.eventGender, competition.competitionGender, skinsMixed);
        const competitionsWithoutCurrent = competitions.filter(c => c.id !== competition.id);
        const individualsNotAvailable = isMain && !isCompatibleCompetition({ scoring: { format: ScoringFormatIndividual.strokeplay } } as Competition, competitionsWithoutCurrent, eventOrRound.teamSize);
        const anotherCompetition = (mainCompetition?.id === competition?.id) ? getEventMainCompetition(competitionsMain.filter(comp => comp.id !== competition.id)) : undefined;
        const incompatibleTeams = anotherCompetition || eventOrRound.teamSize > 2;
        const incompatibleTeamsMessage = eventOrRound.teamSize === 2
            ? `${getScoringLabel(Object.keys(ScoringFormatTeams).filter(f => f !== competition.scoring.format).concat(ScoringFormatSkins.skins_team).reduce((prev, cur) => `${prev}, ${cur}`))} are not compatible with the existing ${getScoringLabel(competition.scoring.format)} competition.`
            : `${getScoringLabel(ScoringFormatTeams.alternate_shot)} and ${getScoringLabel(ScoringFormatTeams.chapman)} formats are not compatible with current teams size.`
        const format = competition.scoring.format === ScoringFormatIndividual.modified_stableford ?
            ScoringFormatIndividual.stableford : competition.scoring.format;
        const stableFordPoints = Scoring.getStablefordPoints(competition.scoring).slice();
        return <>
            <SMMobileDialog open={this.props.open} onClose={handleClose}>
                <DialogAppBar label={title} close={handleClose} />
                <DialogContent>
                    <Flex style={{ width: '100%' }} spaceBetween>
                        <Flex>
                            <FormControl
                                variant="standard"
                                style={{ paddingTop: 8 }}
                                onClick={() => this.setState({ selectingName: true })}>
                                <InputLabel shrink htmlFor="competition-name">Competition name</InputLabel>
                                <Typography style={{ minWidth: 160, height: 20, marginTop: 12 }}>{competitionName}</Typography>
                            </FormControl>
                            <AppButton color="secondary" onClick={() => this.setState({ selectingName: true })}>
                                Change
                            </AppButton>
                        </Flex>
                        <span>
                            {allRounds &&
                                <InfoElement style={{ backgroundColor: AppColors.webGrey100, width: '220px', padding: '10px', fontSize: '12px', borderRadius: '4px' }} iconColor={AppColors.secondary}>
                                    {isNewCompetition ? competitionAddNotice : competitionChangeNotice}
                                </InfoElement>}
                        </span>
                    </Flex>
                    <Spacing />
                    <FormControl variant="standard">
                        <InputLabel shrink htmlFor="select-format-menu">Format</InputLabel>
                        <Select
                            value={format}
                            variant="standard"
                            id="select-format-menu"
                            aria-describedby="select-helper-text"
                            onChange={this.handleFormatChange}>
                            {isMain ? eventOrRound.teamSize === 1 ?
                                <span className={classes.individualOrTeam}>Individual</span> :
                                <span
                                    className={classes.individualOrTeam}>
                                    Team{incompatibleTeams &&
                                        <IconButton size="large" onClick={() => showAlert(incompatibleTeamsMessage)}>
                                            <InfoIcon style={{ transform: 'scale(0.65)' }} />
                                        </IconButton>}
                                </span> : ''}
                            {eventOrRound.teamSize > 1 ? teamFormats.map(f =>
                                <MenuItem
                                    key={f.format}
                                    value={f.format}
                                    disabled={!isCompatibleCompetition({ scoring: { format: f.format } } as Competition, isNewCompetition ? competitions : competitionsWithoutCurrent, eventOrRound.teamSize)}
                                >
                                    {f.label}
                                </MenuItem>) :
                                individualFormats.map(f =>
                                    <MenuItem key={f.format} value={f.format} disabled={individualsNotAvailable}>
                                        {f.label}
                                    </MenuItem>)}
                            {isMain ? eventOrRound.teamSize === 1 ?
                                <span className={classes.individualOrTeam}>Team</span> :
                                <span className={classes.individualOrTeam}>
                                    Individual{incompatibleIndividuals &&
                                        <IconButton
                                            size="large"
                                            onClick={() => showAlert('Individual formats are compatible with Best Ball only.')}>
                                            <InfoIcon style={{ transform: 'scale(0.65)' }} />
                                        </IconButton>}
                                </span> : ''}
                            {isMain && eventOrRound.teamSize === 1 &&
                                <span className={`${classes.paddingLeftRight16} ${classes.setUpTeams}`}>
                                    <span className={`${classes.linkBluePointer}`} onClick={() => replaceUrl(`/events/${event.id}/golfers/teams`)}>
                                        Set up teams
                                    </span> to enable team scoring
                                </span>}
                            {eventOrRound.teamSize > 1 ?
                                individualFormats.map(f =>
                                    <MenuItem key={f.format} value={f.format} disabled={individualsNotAvailable}>
                                        {f.label}
                                    </MenuItem>) :
                                teamFormats.map(f =>
                                    <MenuItem key={f.format} value={f.format} disabled={true}>
                                        {f.label}
                                    </MenuItem>)}
                        </Select>
                    </FormControl>
                    {isTeam &&
                        <FormControl
                            fullWidth
                            margin="dense"
                            variant="standard"
                            style={{ flexDirection: 'row', marginTop: 0 }}>
                            <TypedFormRadioLabel value="strokeplay" label="Strokeplay" currentValue={scoringMode}
                                handleChange={this.handleScoringModeChanged} className={classes.formSelector} />
                            <TypedFormRadioLabel value="stableford" label="Stableford" currentValue={scoringMode}
                                handleChange={this.handleScoringModeChanged} className={classes.formSelector} />
                            <AppButton color="info" onClick={() => this.setState({ showPointSettings: true })}
                                disabled={scoringMode !== 'stableford'} style={{ marginTop: 16 }}><SettingsIcon /> Point Settings</AppButton>
                        </FormControl>}
                    {isStablefordScoring(competition.scoring) && <AppButton color="info" onClick={() => this.setState({ showPointSettings: true })}
                        style={{ marginLeft: 16, marginTop: 16 }}><SettingsIcon /> Point Settings</AppButton>}
                    {!isDistance &&
                        <FormControl
                            fullWidth
                            margin="dense"
                            variant="standard"
                            style={{ flexDirection: 'row' }}>
                            <InputLabel shrink>Handicaps</InputLabel>
                            <TypedFormRadioLabel value="gross" label="Gross" currentValue={competition.scoring.mode}
                                handleChange={this.handleHandicapChange} className={classes.formSelector} />
                            <TypedFormRadioLabel value="net" label="Net" currentValue={competition.scoring.mode}
                                handleChange={this.handleHandicapChange} className={classes.formSelector} />
                            <TypedFormRadioLabel value="gross+net" label="Gross+Net" currentValue={competition.scoring.mode}
                                handleChange={this.handleHandicapChange} className={classes.formSelector} />
                        </FormControl>}
                    {handicapUserNum > 0 && <HandicapPercentSelector value={competition.scoring.handicaps![0]} index={0} handlePercentsChange={this.handleHandicapPercentsChange} individual={handicapUserNum === 1} />}
                    {handicapUserNum > 1 && <HandicapPercentSelector value={competition.scoring.handicaps![1]} index={1} handlePercentsChange={this.handleHandicapPercentsChange} />}
                    {handicapUserNum > 2 && <HandicapPercentSelector value={competition.scoring.handicaps![2]} index={2} handlePercentsChange={this.handleHandicapPercentsChange} />}
                    {handicapUserNum > 3 && <HandicapPercentSelector value={competition.scoring.handicaps![3]} index={3} handlePercentsChange={this.handleHandicapPercentsChange} />}
                    {handicapUserNum > 0 && <NoticeElement>
                        Percentage shown is the most commonly used based on your scoring selection. Organizers can adjust allowance as they see fit.&nbsp;
                        <a href={'https://support.golfpadgps.com/a/solutions/articles/6000266415'} target="_blank" rel="noopener noreferrer" className={classes.linkBluePointer}>Learn more</a>
                    </NoticeElement>}
                    {(isSkins) && <this.SkinsAwarded />}
                    {(isDistance || isSkins) && <this.Holes />}
                    <this.Participants />
                    <this.PayoutSettings />
                </DialogContent>
                <DialogActions>
                    {(competition.id && handleDelete) && <AppButton color="info" onClick={() => handleDelete(competition, allRounds)}>Delete</AppButton>}
                    {competition.id && <span style={{ flex: '1 1 0%' }} />}
                    <AppButton color="info" onClick={handleClose}>Cancel</AppButton>
                    <AppButton color="secondary" onClick={this.handleSave} disabled={doesntFit}>
                        {!isNewCompetition ? 'Save' : isMain ? 'Add competition' : 'Add side game'}
                    </AppButton>
                </DialogActions>
                {selectingHoles && <SelectHolesDialog
                    event={eventOrRound}
                    tee={getTee(eventOrRound, mainCompetition, gender)}
                    competition={competition}
                    handleOk={this.handleHolesOk}
                    handleCancel={this.handleHolesCancel} />}
                {showPointSettings && <StablefordPointSettingsDialog
                    open
                    event={eventOrRound}
                    points={stableFordPoints}
                    savePoints={this.savePoints}
                    onClose={() => this.setState({ showPointSettings: false })} />}
                {selectingName && <CompetitionNameDialog
                    open
                    competition={competition}
                    competitions={competitions}
                    handleSave={this.setCompetitionName}
                    handleClose={() => this.setState({ selectingName: false })} />}
            </SMMobileDialog>
        </>;
    }
}

export default function(props: Omit<Props, 'classes' | 'userAware'>) {
    const classes = useAppStyles();
    const userAware = useUserAware();
    return <CompetitionSettingsDialog classes={classes} userAware={userAware} {...props} />;
}
