import * as React from 'react';
import { ChangeEvent } from 'react';
import Typography from '@mui/material/Typography';
import { Badge, DialogContent, IconButton, ImageList, ImageListItem, ImageListItemBar } from '@mui/material';
import { Theme } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import { SelectedImageIcon, UnselectedImageIcon, UploadIcon } from 'src/common/Icons';
import { WithStyles, StyleRules } from '@mui/styles';
import { XSMobileDialog } from '../../../../common/dialog/MobileDialog';
import DialogAppBar from '../../../../common/dialog/DialogAppBar';
import AppButton from '../../../../common/components/AppButton';
import * as Backend from '../../../../util/firebase';
import ImageCropDialog from '../../../../common/components/ImageCropDialog';
import { showAlert, showError } from '../../../../redux/ReduxConfig';
import { useAppStyles } from "../../../../styles";
import { WithUserAware, useUserAware } from 'src/auth/Auth';
import { SUPPORTED_IMG_FORMATS } from 'src/util/config';
import { Flex, Spacing } from 'src/common/Misc';
import { ImageSrc, ImageType } from 'src/types/EventTypes';

export function supportedImageFormat(type: string) {
    return SUPPORTED_IMG_FORMATS.includes(type);
}

const descriptions: Record<ImageType, any> = {
    badge: {
        header: 'Badges',
        name: 'badge',
        w: 300,
        h: 300,
        cols: 1
    },
    banner: {
        header: 'Banners',
        name: 'banner',
        w: 1000,
        h: 250,
        cols: 4
    },
    sponsor: {
        header: 'Sponsors',
        name: 'sponsor',
        w: 1200,
        h: 400,
        cols: 2
    }
};

const style = (theme: Theme) => {
    return {
        margin: {
            marginTop: theme.spacing(2)
        },
        imageList: {
            height: 450,
            width: '100%',
            overflow: 'unset'
        }
    } as StyleRules;
};

interface ChooseImgDialogProps {
    open: boolean;
    imageType: ImageType;
    handleClose: () => void;
    handleSelection: (url: string) => void;
}

interface Images {
    defaultImgs: Array<ImageSrc>;
    userImgs: Array<ImageSrc>;
}

interface State {
    file?: File;
    selected: Set<string>;
}

const SUPPORTED_IMG = SUPPORTED_IMG_FORMATS.map(s => s.
    replace('image/', '')
    .replace('svg+xml', 'svg')
    .replace('x-icon', 'icon')
    .toUpperCase()).filter(s => s !== 'JPEG');

type Props = ChooseImgDialogProps & WithStyles<typeof style> & Images & WithUserAware & { handleDeleted: () => void; };

class ChooseImgDialog extends React.Component<Props, State> {
    state: State = {
        selected: new Set()
    };

    private handleFile = (ev: ChangeEvent<HTMLInputElement>) => {
        if (!ev.target.files) {
            return;
        }
        if (!supportedImageFormat(ev.target.files[0].type)) {
            showError('Could not read file');
            return;
        }
        this.setState({ file: ev.target.files[0] });
        ev.currentTarget.value = '';
    }

    private setImage = (url: string) => {
        const { imageType, userAware } = this.props;
        if (!userAware.workingUserId) {
            return;
        }
        const data = { url: url, type: imageType } as ImageSrc;
        Backend.updateOrAdd(Backend.userImgDb(userAware.workingUserId), data);
        this.props.handleSelection(url);
        this.closeDialog();
    }

    private closeDialog = () => {
        this.setState({ file: undefined });
    }

    private toggleImgSelect = (id: string) => {
        const { selected } = this.state;
        if (selected.has(id)) {
            selected.delete(id);
        } else {
            selected.add(id);
        }
        this.setState({});
    }

    private deleteImagesConfirm = () => {
        showAlert('You are about to delete selected images.', [
            { title: 'Cancel' },
            { title: 'Confirm and Delete', action: this.deleteImages }
        ]);
    }

    private deleteImages = () => {
        const { userImgs, userAware, handleDeleted } = this.props;
        const { selected } = this.state;
        if (!userAware.workingUserId) {
            return;
        }
        const userImgsToDelete = userImgs.filter(img => selected.has(img.id));
        const changes = userImgsToDelete.map(img => ({ id: img.id, exists: img.exists, deleted: true } as ImageSrc));
        Backend.updateOrAddBatch(Backend.userImgDb(userAware.workingUserId), changes);
        selected.clear();
        this.setState({});
        handleDeleted();
    }

    render() {
        const { classes, open, defaultImgs, userImgs, imageType, handleClose, handleSelection } = this.props;
        const { file, selected } = this.state;
        const desc = descriptions[imageType];
        /*const images = [...defaultImgs];
        images.push(...userImgs);*/
        return <>
            <XSMobileDialog open={open} onClose={handleClose} fullWidth>
                <DialogAppBar label={desc.header} close={handleClose} />
                <DialogContent>
                    <div style={{ textAlign: 'center' }}>
                        <AppButton color="secondary" className={classes.uploadButton}>
                            <label style={{ cursor: 'pointer' }}>
                                <Flex>
                                    <UploadIcon />
                                    Upload new {desc.name} image
                                    <input onChange={this.handleFile} accept={SUPPORTED_IMG_FORMATS.join()}
                                        style={{ display: 'none' }} type="file" />
                                </Flex>
                            </label>
                        </AppButton>
                        <Spacing />
                        <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
                            Format: {SUPPORTED_IMG.join(', ')}
                        </Typography>
                        <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
                            Size: {desc.w} × {desc.h} px
                        </Typography>
                    </div>
                    <div style={{ width: '100%', height: 20 }}>
                        <IconButton
                            color="primary"
                            sx={{ float: 'right' }}
                            onClick={() => this.deleteImagesConfirm()} disabled={selected.size === 0}
                        >
                            <Badge badgeContent={selected.size} color="info">
                                <DeleteIcon />
                            </Badge>
                        </IconButton>
                    </div>
                    <ImageList cols={4} sx={{ maxHeight: 600, overflowY: 'auto', paddingLeft: '4px', paddingRight: '4px' }}>
                        {defaultImgs.map(src => (
                            <ImageListItem key={src.url} cols={desc.cols} className={classes.imageListItem}>
                                <img
                                    alt=""
                                    src={src.url}
                                    onClick={() => handleSelection(src.url)}
                                />
                            </ImageListItem>
                        ))}
                        {userImgs.map(src => (
                            <ImageListItem key={src.url} cols={desc.cols} className={classes.imageListItem}>
                                <img
                                    alt=""
                                    src={src.url}
                                    onClick={() => handleSelection(src.url)}
                                />
                                <ImageListItemBar
                                    position="top"
                                    actionPosition="right"
                                    sx={{ backgroundColor: 'rgba(0,0,0,0.0)' }}
                                    actionIcon={
                                        <IconButton onClick={() => this.toggleImgSelect(src.id)}>
                                            {selected.has(src.id) ?
                                                <SelectedImageIcon sx={{ width: 24, height: 24 }} /> :
                                                <UnselectedImageIcon htmlColor={'rgba(100,100,100,0.25)'} sx={{ width: 24, height: 24 }} />}
                                        </IconButton>
                                    }
                                />
                            </ImageListItem>
                        ))}
                    </ImageList>
                </DialogContent>
            </XSMobileDialog>
            {file && <ImageCropDialog
                file={file}
                width={desc.w}
                height={desc.h}
                rounded={false}
                setImage={this.setImage}
                close={this.closeDialog}
                storageRef={Backend.portalImagesRef}
                maxWidth={desc.w > 300 ? 'sm' : 'xs'}
            />}
        </>;
    }
}

interface Params {
    imageType: ImageType;
}

export default function WPublicImages(props: ChooseImgDialogProps & Params) {
    const [loaded, setLoaded] = React.useState(0);
    const [defaultImgs, setDefaultImgs] = React.useState<Array<ImageSrc>>([]);
    const [userImgs, setUserImgs] = React.useState<Array<ImageSrc>>([]);
    const userAware = useUserAware();
    const classes = useAppStyles();
    const { imageType } = props;
    React.useEffect(() => {
        async function getImages() {
            if (loaded >= 2) {
                return;
            }
            const [defaultImgs, userImgs] = await Promise.all([
                Backend.getEntities<ImageSrc>(Backend.getDefaultImgDb(imageType)),
                Backend.getEntities<ImageSrc>(Backend.getUserImgDb(imageType, userAware.workingUserId!))
            ]);
            setDefaultImgs(defaultImgs.filter(img => !img.deleted));
            setUserImgs(userImgs.filter(img => !img.deleted));
            setLoaded(2);
        }
        getImages();
    }, [loaded]);
    if (!loaded) {
        return null;
    }
    if (!userAware.workingUserId) {
        return null;
    }
    return (
        <ChooseImgDialog
            {...props}
            classes={classes}
            userImgs={userImgs}
            userAware={userAware}
            defaultImgs={defaultImgs}
            handleDeleted={() => setLoaded(1)}
        />)
}
