import * as React from 'react';
import { Box, Checkbox, Divider, Fab, FormControlLabel, Grid, IconButton, List, ListItem, Menu, MenuItem, TextField, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import * as Backend from '../../../../util/firebase';
import { Contact, EventData, SponsorPlacements, getCompetitionsWithPayouts } from '../../../../types/EventTypes';
import { withProgress } from 'src/util/ProgressPromise';
import ChooseImgDialog from './ChooseImgDialog';
import { AppColors } from 'src/main/Theme';
import { LinkIcon, PhotoIcon, PreviewIcon, ReplaceIcon, StarIcon } from 'src/common/Icons';
import DeleteIcon from '@mui/icons-material/Delete';
import { Spacing } from 'src/common/Misc';
import { processEnterKey } from 'src/util/react_utils';
import { AccountActions, accountAction } from 'src/auth/StripeRedirect';
import ProPlanDialog from 'src/auth/ProPlanDialog';
import { useUserAware } from 'src/auth/Auth';
import AppButton from 'src/common/components/AppButton';
import { printCartSigns, printGolfers, printMoney, printAllSchedules, printAllScorecards, printAllResults } from '../../tools/PrintTab';
import { formatDateUniversal } from 'src/event/Event';
import { DAY_MON_DATE_FORMAT } from 'src/util/config';

type Props = { eventData: EventData };

type PlacementPreview = {
    label: string;
    action: VoidFunction;
};

const styles = (theme: Theme) => {
    return {
        root: {
            backgroundColor: AppColors.webGrey100,
            padding: theme.spacing(2),
            margin: 0
        },
        list: {
            backgroundColor: AppColors.webGrey100
        },
        img: {
            width: '100%',
        },
        urlContainer: {
            maxWidth: 600,
            backgroundColor: AppColors.white,
        },
        imgComponentContainer: {
            display: 'inline-block',
            width: '80%',
            maxWidth: 400,
        },
        placeholder: {
            '&::placeholder': {
                color: AppColors.webBlue400
            }
        },
        txtimgContainer: {
            display: 'inline-block',
            width: '100%',
        },
        imgContainer: {
            padding: theme.spacing(1),
            cursor: 'pointer',
            display: 'inline-block',
            width: '100%',
            border: '2px dashed ' + AppColors.webBlue200,
            backgroundColor: AppColors.white,
            '&:hover': {
                border: '2px dashed ' + AppColors.webBlue400,
                backgroundColor: AppColors.webBlue100
            }
        },
        noimgContainer: {
            cursor: 'pointer',
            display: 'inline-block',
            width: '100%',
            border: '2px dashed ' + AppColors.webBlue200,
            backgroundColor: AppColors.white,
            margin: theme.spacing(2),
            marginRight: theme.spacing(1),
            '&:hover': {
                border: '2px dashed ' + AppColors.webBlue400,
                backgroundColor: AppColors.webBlue100
            },
        },
        imgPage: {
            margin: theme.spacing(1),
            display: 'block'
        },
        toolbarButtonPro: {
            color: AppColors.webGreen100,
            backgroundColor: AppColors.webGreen500,
            '&:hover': {
                backgroundColor: AppColors.webGreen600
            }
        }
    }
};

const useStyles = makeStyles(styles);

function sponsorPlacementsKeys(sponsorPlacements: any) {
    return (Object.keys(sponsorPlacements) as [keyof SponsorPlacements]);
}

function toPlacementsMap(sponsorPlacements?: SponsorPlacements) {
    const placementsSet = new Set<string>();
    if (sponsorPlacements) {
        sponsorPlacementsKeys(sponsorPlacements).forEach(p => {
            if (sponsorPlacements[p]) {
                placementsSet.add(p);
            }
        });
    }
    return placementsSet;
}

export default function SponsorshipSettings({ eventData }: Props) {
    const { event, portal, rounds, golfersMap, competitionsMap } = eventData;
    const editMenu = React.useRef<HTMLButtonElement>(null);
    const eventOrRound = rounds.length > 0 ? rounds[0] : event;
    const golfers = golfersMap.get(eventOrRound.id) ?? new Map<string, Contact>();
    const competitions = competitionsMap.get(eventOrRound.id) ?? [];
    const noMoney = golfers.size === 0 || getCompetitionsWithPayouts(competitions).length === 0;
    const placementsActions: Record<keyof SponsorPlacements, PlacementPreview> = {
        printScorecard: {
            label: 'Scorecard',
            action: () => userAware.hasPro ? printAllScorecards(eventData) : showProBanner(true)
        },
        printGolfers: {
            label: 'Golfers list',
            action: () => userAware.hasPro ? printGolfers(event, eventData) : showProBanner(true)
        },
        printSchedule: {
            label: 'Schedule',
            action: () => userAware.hasPro ? printAllSchedules(eventData) : showProBanner(true)
        },
        printResults: {
            label: 'Results',
            action: () => userAware.hasPro ? printAllResults(eventData) : showProBanner(true)
        },
        printCartSigns: {
            label: 'Cart signs',
            action: () => userAware.hasPro ? printCartSigns(event, eventData) : showProBanner(true)
        },
        printMoneyList: {
            label: 'Money list',
            action: () => userAware.hasPro ? printMoney(eventData) : showProBanner(true)
        },
        eventSite: {
            label: 'Event site',
            action: () => window.open(`${window.location.origin}/event/${event.publicId}`)
        },
        tvStandings: {
            label: 'TV standings',
            action: () => window.open(`${window.location.origin}/tv/${event.publicId}`)
        },
    };
    React.useEffect(() => {
        Backend.trackEvent('view_sponsor_settings');
    });
    const classes = useStyles();
    const userAware = useUserAware();
    const [chooseImgOpened, setChooseImgOpened] = React.useState(false);
    const [proBanner, showProBanner] = React.useState(false);
    const [editMenuOpen, setEditMenuOpen] = React.useState(false);
    const [placements, setPlacements] = React.useState<Set<string>>(toPlacementsMap(portal.sponsorPlacements));
    const [sponsorSiteUrl, setSponsorSiteUrl] = React.useState(portal.sponsorSiteUrl ?? '');
    const [allSelected, setAllSelected] = React.useState(placements.size === Object.keys(placementsActions).length);
    function updatePlacements(ps: Set<string>) {
        const sponsorPlacements: SponsorPlacements = {};
        sponsorPlacementsKeys(placementsActions).forEach(p => sponsorPlacements[p] = ps.has(p));
        portal.sponsorPlacements = sponsorPlacements;
        withProgress(Backend.updateOrAdd(Backend.portalInfoDb, portal));
    }
    function handleCloseProPlan(name?: AccountActions) {
        showProBanner(false);
        accountAction(userAware, name);
    }
    function handleMenuClose() {
        setEditMenuOpen(false);
    }
    function handleImgClick() {
        if (!userAware.hasPro) {
            showProBanner(true);
            return;
        }
        setChooseImgOpened(true);
    }
    function handleDialogClose() {
        setChooseImgOpened(false)
    };
    function saveSponsorLogo(url: string) {
        if (portal) {
            portal.sponsorLogoUrl = url;
            withProgress(Backend.updateOrAdd(Backend.portalInfoDb, portal));
        }
        handleDialogClose();
    }
    function saveSponsorUrl() {
        if (portal) {
            portal.sponsorSiteUrl = sponsorSiteUrl.trim();
            withProgress(Backend.updateOrAdd(Backend.portalInfoDb, portal));
            setSponsorSiteUrl(sponsorSiteUrl.trim());
        }
    }
    function replaceSponsorLogo() {
        handleMenuClose();
        handleImgClick();
    }
    function removeSponsorLogo() {
        handleMenuClose();
        if (portal) {
            delete portal.sponsorLogoUrl;
            withProgress(Backend.removeField(Backend.portalInfoDb, portal, 'sponsorLogoUrl'));
        }
    }
    function selectAll(checked: boolean) {
        if (!userAware.hasPro) {
            showProBanner(true);
            return;
        }
        setPlacements(() => {
            const newSet = new Set<string>();
            if (checked) {
                Object.keys(placementsActions).forEach(placement => newSet.add(placement));
            }
            updatePlacements(newSet);
            return newSet;
        });
        setAllSelected(checked);
    }
    function select(placement: string, checked: boolean) {
        if (!userAware.hasPro) {
            showProBanner(true);
            return;
        }
        setPlacements(prevSet => {
            const newSet = new Set(prevSet);
            if (checked) {
                newSet.add(placement);
            } else {
                newSet.delete(placement);
            }
            if (newSet.size === 0) {
                setAllSelected(false);
            } else if (newSet.size === Object.keys(placementsActions).length) {
                setAllSelected(true);
            }
            updatePlacements(newSet);
            return newSet;
        });
    }
    const sponsorLogoUrl = portal.sponsorLogoUrl;
    const pls = sponsorPlacementsKeys(placementsActions);
    const logoShows = (portal.sponsorCounters?.eventSiteShows ?? 0) + (portal.sponsorCounters?.tvStandingsShows ?? 0);
    const logoClicks = (portal.sponsorCounters?.eventSiteClicks ?? 0) + (portal.sponsorCounters?.tvStandingsClicks ?? 0);
    return <>
        <div className={classes.root}>
            <div style={{ backgroundColor: AppColors.white }}>
                <Grid container direction={'row-reverse'}>
                    <Grid item xs={12} sm={12} md={6} className={classes.list} alignContent={'center'}>
                        <div className={classes.imgPage} style={{ textAlign: 'left', padding: 8 }}>
                            {!userAware.hasPro &&
                                <Typography>
                                    Sponsorship settings are active in the Pro
                                    <Spacing height={8} />
                                </Typography>}
                            {!userAware.hasPro && <div>
                                <AppButton
                                    sx={{ minWidth: '100px' }}
                                    className={classes.toolbarButtonPro}
                                    onClick={() => showProBanner(true)}
                                >
                                    <StarIcon sx={{ width: '16px', paddingRight: '4px' }} /> Get Pro
                                </AppButton>
                                <Spacing />
                            </div>}
                            <span className={classes.imgComponentContainer}>
                                <Typography sx={{ fontSize: '14px', fontWeight: 500 }} className={classes.txtimgContainer}>
                                    Sponsor image
                                </Typography>
                                {sponsorLogoUrl ?
                                    <span onClick={handleImgClick} className={classes.imgContainer} >
                                        <img src={sponsorLogoUrl} className={classes.img} alt=""
                                            style={{ display: 'inline-block', verticalAlign: 'top' }}
                                        />
                                    </span> : <span onClick={handleImgClick} className={classes.noimgContainer} style={{ position: 'relative', aspectRatio: 3 }}>
                                        <div style={{
                                            position: 'absolute',
                                            top: '50%', left: '50%',
                                            transform: 'translate(-50%, -50%)'
                                        }}>
                                            <PhotoIcon color="primary" />
                                            <Typography sx={{ fontSize: '16px', fontWeight: 500, color: AppColors.webBlue400 }}>
                                                Upload an image
                                            </Typography>
                                        </div>
                                    </span>}
                            </span>
                            {sponsorLogoUrl &&
                                <span style={{ display: 'inline-block', verticalAlign: 'top' }}>
                                    <Fab color="secondary" sx={{ width: '28px', height: '28px', minHeight: '28px' }}
                                        ref={editMenu} onClick={() => setEditMenuOpen(true)}>
                                        <MoreHorizIcon fontSize="small" />
                                    </Fab>
                                </span>}
                            <Spacing />
                            <Typography>
                                Sponsor landing page (optional)
                            </Typography>
                            <TextField
                                type="text"
                                size={'medium'}
                                variant="outlined"
                                placeholder="https://example.com"
                                disabled={!userAware.hasPro}
                                value={sponsorSiteUrl}
                                className={classes.urlContainer}
                                onKeyDown={event => processEnterKey(event, saveSponsorUrl)}
                                onChange={e => setSponsorSiteUrl(e.target.value)}
                                InputProps={{
                                    classes: {
                                        input: classes.placeholder
                                    },
                                    startAdornment: <LinkIcon sx={{ mr: 1 }} color={userAware.hasPro ? 'primary' : 'inherit'} />,
                                    endAdornment: sponsorSiteUrl.trim() !== (portal?.sponsorSiteUrl ?? '') ?
                                        <AppButton
                                            onClick={saveSponsorUrl}
                                            sx={{ fontSize: '12px', height: 24, minHeight: 24 }}>
                                            Save
                                        </AppButton> : undefined
                                }}
                            />
                            <Menu
                                id="basic-menu"
                                open={editMenuOpen}
                                onClose={handleMenuClose}
                                anchorEl={editMenu.current}
                            >
                                <MenuItem sx={{ color: AppColors.webBlue500 }} onClick={replaceSponsorLogo}><ReplaceIcon sx={{ mr: 1 }} /> Replace</MenuItem>
                                <MenuItem sx={{ color: AppColors.webBlue500 }} onClick={removeSponsorLogo}><DeleteIcon sx={{ mr: 1 }} /> Remove</MenuItem>
                            </Menu>
                            <Spacing />
                            <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
                                Performance Summary
                            </Typography>
                            {portal.sponsorCounters?.startTime &&
                                <Typography sx={{ fontSize: '12px', fontWeight: 400, color: AppColors.webGrey400 }}>
                                    Start date — {formatDateUniversal(portal.sponsorCounters.startTime, DAY_MON_DATE_FORMAT)}
                                </Typography>}
                            <Grid container sx={{ width: 400 }}>
                                <span style={{ backgroundColor: AppColors.white, width: 90, height: 50, margin: '8px', padding: '8px', marginLeft: 0 }}>
                                    <Typography sx={{ fontSize: '12px', fontWeight: 400, color: AppColors.webGrey400 }}>Impressions</Typography>
                                    <Typography sx={{ fontSize: '20px', fontWeight: 500 }}>{logoShows}</Typography>
                                </span>
                                <span style={{ backgroundColor: AppColors.white, width: 90, height: 50, margin: '8px', padding: '8px', marginLeft: 0 }}>
                                    <Typography sx={{ fontSize: '12px', fontWeight: 400, color: AppColors.webGrey400 }}>Clicks</Typography>
                                    <Typography sx={{ fontSize: '20px', fontWeight: 500 }}>{logoClicks}</Typography>
                                </span>
                            </Grid>
                            <Typography sx={{ fontSize: '12px', fontWeight: 400, color: AppColors.webGrey400 }}>
                                Impressions for printed materials are not included
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Typography sx={{ ml: 2, mt: 2, fontSize: '20px', fontWeight: 500, color: AppColors.webBlue500 }}>
                            Select Image Placement
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'column', m: 1 }}>
                            <List sx={{ maxWidth: '100%' }}>
                                <ListItem>
                                    <FormControlLabel
                                        label="Select All"
                                        slotProps={{
                                            typography: { sx: { ml: 2 } }
                                        }}
                                        control={<Checkbox
                                            color='secondary'
                                            checked={allSelected}
                                            onClick={e => { e.preventDefault(); e.stopPropagation(); selectAll(!allSelected); }}
                                            indeterminate={0 < placements.size && placements.size < Object.keys(placementsActions).length}
                                        />}
                                    />
                                </ListItem>
                                <Divider sx={{ ml: 1 }} />
                                {pls.map((placement, idx) => <div key={idx}>
                                    {placement === 'printScorecard' &&
                                        <Typography sx={{ ml: 1, mt: 2, fontSize: '14px', fontWeight: 400, color: AppColors.webGrey400 }}>
                                            Printed materials
                                        </Typography>}
                                    {placement === 'eventSite' &&
                                        <Typography sx={{ ml: 1, mt: 2, fontSize: '14px', fontWeight: 400, color: AppColors.webGrey400 }}>
                                            Electronic materials
                                        </Typography>}
                                    <ListItem sx={{ minWidth: '100%', padding: 0, paddingLeft: '16px' }}>
                                        <FormControlLabel
                                            key={placement}
                                            label={placementsActions[placement].label}
                                            slotProps={{
                                                typography: { sx: { ml: 2 } }
                                            }}
                                            control={<Checkbox
                                                color='secondary'
                                                checked={placements.has(placement)}
                                                disabled={placement === 'printMoneyList' && noMoney}
                                                onClick={e => { e.preventDefault(); e.stopPropagation(); select(placement, !placements.has(placement)); }}
                                            />}
                                        />
                                        <span style={{ flex: "1" }} />
                                        <span>
                                            <IconButton
                                                color="secondary"
                                                sx={{ padding: 1 }}
                                                onClick={placementsActions[placement].action}
                                                disabled={placement === 'printMoneyList' && noMoney}>
                                                <PreviewIcon />
                                            </IconButton>
                                        </span>
                                    </ListItem>
                                    {idx < pls.length - 1 && <Divider sx={{ ml: 1 }} />}
                                </div>)}
                            </List>
                        </Box>
                    </Grid>
                </Grid>
                {chooseImgOpened && <ChooseImgDialog
                    open
                    imageType={'sponsor'}
                    handleClose={handleDialogClose}
                    handleSelection={saveSponsorLogo}
                />}
                {proBanner &&
                    <ProPlanDialog handleClose={handleCloseProPlan} />}

            </div>
        </div >
    </>;
}
