import * as React from 'react';
import { AppBar, Box, Hidden, IconButton, Toolbar } from '@mui/material';
import { CookiesProvider } from "react-cookie";
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { Routes, Route, Navigate, useLocation, useParams, createBrowserRouter, RouterProvider } from 'react-router-dom';
import RefreshIcon from '@mui/icons-material/Refresh';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { detectMob } from '../util/react_utils';
import * as Backend from '../util/firebase';
import NavigationMenu from './NavigationMenu';
import { RemoteLogin, RemoteSignup, UserAwareProvider, useUserAware } from '../auth/Auth';
import Login from 'src/auth/Login';
import LoginRedirect from 'src/auth/LoginRedirect';
import Account from 'src/auth/Account';
import { theme } from './Theme';
import GolfpadEventsLogo from '../common/Logo';
import EventList from '../event/list/EventList';
import { ReduxRoot, pushUrl, replaceUrl } from '../redux/ReduxConfig';
import EventInfo from '../event/list/EventInfo';
import Public, { PublicConfirmation } from '../public/Public';
import TVLeaderboard from '../public/TVLeaderboard';
import UserList from '../users/UserList';
import TestList from './TestList';
import ErrorBoundary from './ErrorBoundary';
import { appToolbarHeight, useAppStyles, welcomeImage } from '../styles';
import { PayPalConfig, DEBUG, TEST_SERVER, Urls, VERSION, ITEM_ABOUT_ID } from '../util/config';
import LeaderboardInfo from '../public/Leaderboard';
import { getTimeZoneOffsetMs, dbgLog, logInfo } from "../util/utility";
import { Announcement, Event } from '../types/EventTypes';
import { registerServiceWorker, detectServiceWorkerUpdate } from './SWRegistration';
import { Flex } from '../common/Misc';
import { FirebaseDocComponent } from 'src/common/WithData';
import AnnouncementAlert from './AnnouncementAlert';
import { PayPalScriptProvider, ReactPayPalScriptOptions } from "@paypal/react-paypal-js";
import LocationProviderComponent from "../common/components/LocationProviderComponent";
import HandicapCalc from './HandicapCalc';

const ITEM_SET_EID = "setEID";
const reloading = window.location.search.indexOf('reload') > 0;

function saveAbout() {
    location.search.split(/[&]|[?]/).forEach(par => {
        const X = 'about=';
        if (par.startsWith(X)) {
            const aboutID = par.substring(X.length);
            dbgLog('From about:', aboutID, 'old value:', localStorage.getItem(ITEM_ABOUT_ID));
            localStorage.setItem(ITEM_ABOUT_ID, aboutID);
            replaceUrl(window.location.pathname);
        }
    })
}

if (window.location.search) {
    setTimeout(saveAbout);
}

if (reloading) {
    registerServiceWorker(false, () => window.location.replace(window.location.pathname));
}

function processSearchParams() {
    location.search.split(/[&]|[?]/).forEach(par => {
        const fromPar = 'from=';
        if (par.startsWith(fromPar)) {
            const from = par.substring(fromPar.length);
            dbgLog('From:', from, window.location.pathname);
            if (from === 'about') {
                Backend.trackEvent('top_get_started');
            }
            replaceUrl(window.location.pathname);
        }
    });
}

if (window.location.search) {
    setTimeout(processSearchParams);
}

class Err extends React.Component {
    state = { err: false };
    render() {
        const { err } = this.state;
        if (err) {
            throw new Error(`Simulated crash`);
        }
        setTimeout(() => this.setState({ err: true }), 3000);
        return <span>Test page</span>;
    }
}

export interface AppState {
    isClosingDrawer: boolean;
    setIsClosingDrawer: (val: boolean) => void;
    mobileDrawerOpen: boolean;
    setMobileDrawerOpen: (val: boolean) => void;
}

export const AppStateContext = React.createContext<AppState>(null!);

function AppStateProvider({ children }: { children: React.ReactNode }) {
    const [isClosingDrawer, setIsClosingDrawer] = React.useState(false);
    const [mobileDrawerOpen, setMobileDrawerOpen] = React.useState(false);
    return (
        <AppStateContext.Provider
            value={{
                isClosingDrawer, setIsClosingDrawer,
                mobileDrawerOpen, setMobileDrawerOpen
            }}
        >
            {children}
        </AppStateContext.Provider>
    );
}

export function useAppState() {
    return React.useContext(AppStateContext);
}

function AdminAppBar() {
    const userAware = useUserAware();
    const appState = useAppState();
    const { hasPro, setHasUpdated } = userAware;
    const [updateAvailable, setUpdateAvailable] = React.useState(false)
    const [updateCalled, setUpdateCalled] = React.useState(false)
    React.useEffect(() => {
        const detectSW = () => {
            if (!reloading) {
                detectServiceWorkerUpdate(() => {
                    setHasUpdated(true);
                    setUpdateAvailable(true);
                });
            }
        }
        detectSW();
    }, [hasPro, updateAvailable]);
    const doUpdate = () => {
        setUpdateCalled(true);
        window.location.reload();
    }
    const openEvents = () => {
        return updateAvailable ? window.location.replace('/events') : pushUrl('/events')
    }
    const handleDrawerToggle = () => {
        appState.setMobileDrawerOpen(!appState.mobileDrawerOpen);
    };
    const drawerButton = location.pathname.startsWith('/events') || location.pathname.startsWith('/account/');
    return <>
        <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <Toolbar variant="dense" sx={{ height: appToolbarHeight }}>
                {drawerButton &&
                    <Hidden mdUp>
                        <IconButton color="inherit" onClick={handleDrawerToggle}>
                            {appState.mobileDrawerOpen ?
                                <CloseIcon color="inherit" /> : <MenuIcon color="inherit" />}
                        </IconButton>
                        <span style={{ width: '10px' }} />
                    </Hidden>}
                <GolfpadEventsLogo onclk={openEvents} hasPro={hasPro} />
                {updateAvailable && !updateCalled && <NewVersionMenu updateServiceWorker={doUpdate} />}
                <NavigationMenu />
            </Toolbar>
            <FirebaseDocComponent
                docReference={Backend.mainAnnouncementDoc()}
                onDoc={doc => userAware.setAnnouncement(Backend.fromEntity<Announcement>(doc))} />
        </AppBar>
        <Box sx={{ height: appToolbarHeight }} />
        <AnnouncementAlert announcement={userAware.announcement} />
    </>;
}

function NewVersionMenu({ updateServiceWorker }: { updateServiceWorker: VoidFunction }) {
    function updateSite(e: React.MouseEvent) {
        e.preventDefault();
        updateServiceWorker();
    }
    return (
        <Flex>
            New version is available,&nbsp; <a href="/" onClick={updateSite}><Flex>refresh now <RefreshIcon /></Flex></a>
        </Flex>
    );
}

const EventInviteRouteComponent = () => {
    const { id: eventInviteId } = useParams<'id'>();
    React.useEffect(() => {
        const fetchEvent = async () => {
            const event = await Backend.getEntity<Event>(Backend.eventsDb, eventInviteId);
            if (!event.exists || !event.publicId || event.publicId === '') {
                window.location.href = `${Urls.baseUrl}/events`;
            } else {
                window.location.href = `${Urls.baseUrl}/event/${event.publicId}/standings/invite`;
            }
        }
        fetchEvent();
    }, []);
    return null;
}

export function LandingPage() {
    React.useEffect(() => {
        window.location.href = Urls.landingPageLink;
    });
    return null;
}

function AdminRoutingComponent() {
    const userAware = useUserAware();
    const location = useLocation();
    const { loginStatus, signingOut, hasSup } = userAware;
    if (signingOut) {
        return null;
    }
    //const localHost = window.location.origin.includes('://localhost');
    const subscribed = location.pathname === '/events/subscribed';
    return <>
        <AdminAppBar />
        <Routes>
            <Route path="/sso/:token" element={<LoginRedirect />} />
            {loginStatus === 'Logging' && <>
                Logging...
            </>}
            {loginStatus === 'None' && <>
                <Route path="/remoteLogin" element={<RemoteLogin />} />
                <Route path="/remoteSignup" element={<RemoteSignup />} />
                {TEST_SERVER && <Route path="/login" element={<Login />} />}
                {TEST_SERVER && <Route path="/*" element={<Navigate to="/login" state={{ from: location }} replace />} />}
                {!TEST_SERVER && <Route path="/*" element={<LandingPage />} />}
            </>}
            {loginStatus === 'Logged' && <>
                {(hasSup || TEST_SERVER || DEBUG) && <Route path="/sup/*" element={<UserList />} />}
                <Route path="/account/*" element={<Account />} />
                <Route path="/events/subscribed?" element={<EventList subscribed={subscribed} />} />
                <Route path="/events/:id/*" element={<EventInfo />} />
                <Route path="/errtest" element={<Err />} />
                <Route path="/*" element={<Navigate to="/events" replace />} />
            </>}
        </Routes>
    </>;
}

function AdminContent() {
    const userAware = useUserAware();
    const classes = useAppStyles();
    const pathName = location.pathname;
    const { user, effectiveUserId, setEffectiveUserId } = userAware;
    const eidToSet = localStorage.getItem(ITEM_SET_EID);
    if (eidToSet) {
        setEffectiveUserId(eidToSet);
        localStorage.removeItem(ITEM_SET_EID);
    }
    return <>
        <div className={classes.welcome}>
            <Routes>
                <Route path="/test" element={<TestList />} />
                <Route path="/calc" element={<HandicapCalc />} />
                <Route path='/leaderboards/:id' element={<LeaderboardInfo />} />
                <Route path="/*" element={<AdminRoutingComponent />} />
            </Routes>
        </div>
        {DEBUG &&
            <div style={{
                color: 'rgb(115, 90, 0)',
                backdropFilter: 'blur(5px)',
                backgroundColor: 'rgba(0, 150, 170, 0.4)',
                fontSize: '12px', fontFamily: 'monospace', fontWeight: 600,
                position: 'fixed', bottom: 0, right: 0, zIndex: 10000000,
                paddingLeft: 11, paddingRight: 3, paddingTop: 5, paddingBottom: 3,
                borderRadius: '23px  0px 0px 0px',
            }}>
                <span style={{ padding: 4 }}>{VERSION}</span>
                <span style={{ padding: 4 }}>{user?.uid}</span>
                <span style={{ padding: 4 }}>{user ? (user.email || 'No email') : ''}</span>
                <span style={{ padding: 4 }}>{effectiveUserId}</span>
            </div>}
        {pathName === '/login' && <footer className={classes.welcomeFooter}>
            <img className={classes.welcomeImage} src={welcomeImage} alt="" />
        </footer>}
    </>;
}

const paypalInitialOptions: ReactPayPalScriptOptions = {
    clientId: PayPalConfig.payPalClientId,
    intent: 'capture'
};

function Root() {
    const hasNative = document && (document.elementsFromPoint);
    function getDropTargetElementsAtPoint(x: number, y: number, dropTargets: HTMLElement[]) {
        return dropTargets.filter(t => {
            const rect = t.getBoundingClientRect();
            return x >= rect.left && x <= rect.right && y <= rect.bottom && y >= rect.top;
        });
    }
    // use custom function only if elementsFromPoint is not supported
    const backendOptions = {
        enableTouchEvents: true,
        enableMouseEvents: false,
        ignoreContextMenu: true,
        getDropTargetElementsAtPoint: !hasNative && getDropTargetElementsAtPoint,
    };
    const fromPublic = window.location.pathname.includes('/invite-decline/') ||
        window.location.pathname.includes('/invite-accept/') ||
        window.location.pathname.includes('/event/') ||
        window.location.pathname.includes('/eventInvites/') ||
        window.location.pathname.includes('/tv/');
    return (
        <PayPalScriptProvider options={paypalInitialOptions}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <DndProvider backend={detectMob() ? TouchBackend : HTML5Backend} options={backendOptions}>
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={theme}>
                            <CookiesProvider>
                                <ReduxRoot>
                                    <Routes>
                                        // ++ public
                                        <Route path="/eventInvites/:id/*" element={<EventInviteRouteComponent />} />
                                        <Route path="/event/:id/*" element={<Public />} />
                                        <Route path="/invite-accept/:id/*" element={<PublicConfirmation />} />
                                        <Route path="/invite-decline/:id/*" element={<PublicConfirmation />} />
                                        <Route path="/tv/:id" element={<TVLeaderboard />} />
                                        // -- public
                                        {!fromPublic && <Route path="/*"
                                            element={<UserAwareProvider>
                                                <ErrorBoundary>
                                                    <AppStateProvider>
                                                        <AdminContent />
                                                    </AppStateProvider>
                                                </ErrorBoundary>
                                            </UserAwareProvider>}
                                        />}
                                    </Routes>
                                </ReduxRoot>
                            </CookiesProvider>
                        </ThemeProvider>
                    </StyledEngineProvider>
                </DndProvider>
            </LocalizationProvider>
            <LocationProviderComponent />
        </PayPalScriptProvider>
    );
}

const router = createBrowserRouter([
    { path: "*", element: <Root /> }
]);

export default function App() {
    Backend.trackEvent('app_start');
    logInfo(`lang: ${navigator.language}`);
    logInfo(`ver: ${VERSION}`);
    logInfo(`tz : ${getTimeZoneOffsetMs(Date.now())}`);
    logInfo(`env: ${process.env.NODE_ENV}`);
    return <RouterProvider router={router} />;
}
