import * as React from 'react';
import { DialogActions, DialogContentText, DialogContent, List, ListItem, Divider, Typography } from '@mui/material';
import { Event, Contact, ContactDetails, Competition, SpreadsheetImportResult, ACTION_GOLFER_IMPORTED } from '../../types/EventTypes';
import { trimmedNameLastName } from '../Contact';
import { saveContacts } from '../../event/Event';
import { WithStyles } from '@mui/styles';
import { XSMobileDialog } from '../../common/dialog/MobileDialog';
import AppButton from '../../common/components/AppButton';
import DialogAppBar from '../../common/dialog/DialogAppBar';
import { Item, Container } from '../../common/Misc';
import { useAppStyles, styles } from '../../styles';
import * as Utils from '../../util/utility';
import { Urls } from '../../util/config';
import * as Backend from '../../util/firebase';
import { FirebaseDataComponent } from '../../common/WithData';
import { useUserAware } from 'src/auth/Auth';
import { CheckboxWithToolTipControl } from "../../common/components/CheckboxWithToolTipControl";

type Props = {
    event: Event;
    workingUserId: string;
    handleClose: () => void;
    loadedData: SpreadsheetImportResult;
} & WithStyles<typeof styles>;

interface State {
    contactDetails: Map<string, ContactDetails>;
    processedData?: SpreadsheetImportResult;
    golfers: Map<string, Contact>;
    competitions: Array<Competition>;
    notifyGolfers: boolean;
}

class ImportReviewDialog extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            golfers: new Map<string, Contact>(),
            contactDetails: new Map<string, ContactDetails>(),
            competitions: [],
            notifyGolfers: true
        };
        this.processData();
    }

    private onCompetitions = (competitions: Array<Competition>) => this.setState({ competitions });
    private onContactDetails = (contactDetails: Map<string, ContactDetails>) => this.setState({ contactDetails });
    private onGolfers = (golfers: Map<string, Contact>) => this.setState({ golfers });

    private processData = () => {
        const { event, loadedData } = this.props;
        const { golfers, contactDetails } = this.state;
        loadedData.added = 0; loadedData.updated = 0; loadedData.rejected = 0;
        if (loadedData && loadedData.contacts && loadedData.contacts.length) {
            loadedData.contacts.forEach((ic, i) => {
                if (!ic.lastName) {
                    loadedData.statuses.set(i, 'No last name');
                    loadedData.rejected++;
                } else if (!ic.gender) {
                    loadedData.statuses.set(i, 'No gender');
                    loadedData.rejected++;
                } else if (event.eventGender !== 'both' && ((event.eventGender === 'men' && ic.gender !== 'male') || (event.eventGender === 'women' && ic.gender !== 'female'))) {
                    loadedData.statuses.set(i, 'Wrong gender');
                    loadedData.rejected++;
                } else if (ic.lastName && ic.lastName.length > 100) {
                    loadedData.statuses.set(i, 'To long last name');
                    loadedData.rejected++;
                } else if (ic.firstName && ic.firstName!.length > 100) {
                    loadedData.statuses.set(i, 'To long first name');
                    loadedData.rejected++;
                } else if (ic.phone && ic.phone!.length > 100) {
                    loadedData.statuses.set(i, 'To long phone');
                    loadedData.rejected++;
                } else if (ic.notes && ic.notes!.length > 100) {
                    loadedData.statuses.set(i, 'To long notes');
                    loadedData.rejected++;
                } else {
                    const ec = Array.from(golfers.values()).find(c => trimmedNameLastName(c) === trimmedNameLastName(ic) && c.hidden !== true);
                    const rc = Array.from(contactDetails.values()).find(c => trimmedNameLastName(c) === trimmedNameLastName(ic));
                    if (!rc && !ec) {
                        loadedData.statuses.set(i, 'Add');
                        loadedData.added++;
                    } else if (!!rc && !ec) {
                        loadedData.statuses.set(i, 'Add');
                        loadedData.added++;
                    } else if (!!ec) {
                        loadedData.statuses.set(i, 'Update');
                        loadedData.updated++;
                    }
                }
            });
        }
    }

    private importData = () => {
        const { event, loadedData } = this.props;
        const { notifyGolfers } = this.state;
        let contacts = new Array<ContactDetails>();
        loadedData.contacts.forEach((ic, i) => {
            if (loadedData.statuses.get(i) === 'Add' || loadedData.statuses.get(i) === 'Update') {
                contacts.push(ic);
            }
        });
        saveContacts(event, contacts, ACTION_GOLFER_IMPORTED, !notifyGolfers).then(this.props.handleClose);
    }

    Results = (params: { result: SpreadsheetImportResult }) => {
        const { classes } = this.props;
        const { result } = params;
        return (
            <List disablePadding>
                <ListItem button className={classes.listItemHeaderWhite}>
                    <Container wrap="nowrap">
                        <Item xs={4} variant="body2">Name</Item>
                        <Item xs={2} variant="body2">Gender</Item>
                        <Item xs={1} variant="body2">Index</Item>
                        <Item xs={3} variant="body2">Email</Item>
                        <Item xs={2} variant="body2">Status</Item>
                    </Container>
                </ListItem>
                <Divider />
                <Divider />
                {result.contacts.map((contact, index) => <div>
                    <ListItem className={classes.listItem}>
                        <Container wrap="nowrap">
                            <Item xs={4} variant="body2">{(contact.firstName || '') + ' ' + (contact.lastName || '')}</Item>
                            <Item xs={2} variant="body2">{(contact.gender || '')}</Item>
                            <Item xs={1} variant="body2">{(contact.handicapIndex || '')}</Item>
                            <Item xs={3} variant="body2" paddingRight={4}>{(contact.email || '')}</Item>
                            {result.statuses.get(index) !== 'Add' && result.statuses.get(index) !== 'Update' && <Item xs={2} className={classes.redText}>{result.statuses.get(index)}</Item>}
                            {(result.statuses.get(index) === 'Add' || result.statuses.get(index) === 'Update') && <Item xs={2}>{result.statuses.get(index)}</Item>}
                        </Container>
                    </ListItem>
                    <Divider />
                </div>)}
            </List>
        );
    }

    render() {
        const { loadedData, event, classes, workingUserId } = this.props;
        const { processedData, notifyGolfers } = this.state;
        const toImport = loadedData.added + loadedData.updated;
        const errorContent = <DialogContentText>{loadedData!.error}</DialogContentText>
        const loadedContent = <this.Results result={loadedData!} />;
        const checkBoxTooltip = "An email containing a link to the Event site and an app invitation will be sent to golfers who have provided email addresses. "
            + "Once a golfer receives the email, they can add the event to the Golf Pad app to view the schedule, standings, and post live scores.";
        const checkBoxLabel = "Notify golfers via email";
        const checkBoxControl = <CheckboxWithToolTipControl label={checkBoxLabel}
            tooltip={checkBoxTooltip}
            checked={notifyGolfers}
            onChange={(e) => this.setState({ notifyGolfers: e.target.checked })} />;
        const submitLabel = notifyGolfers ? "Import and notify" : "Import";
        return <>
            <XSMobileDialog open onClose={() => this.props.handleClose()} maxWidth={'lg'} fullWidth={true}>
                <DialogAppBar label={loadedData!.error ? 'Import failed' : 'Import review'} />
                <DialogContent className={classes.dialogContent}>
                    {!loadedData!.error ? loadedContent : errorContent}
                    <Typography className={classes.paper}>
                        <span
                            className={classes.boldText}>Total: </span>{Utils.withS(loadedData.added, 'new golfer')}, {Utils.withS(loadedData.updated, 'updated golfer')} {loadedData.rejected > 0 ? `, ${loadedData.rejected} incomplete` : ''} {loadedData.rejected > 0 &&
                                <a href={Urls.helpImportURL} target="help"
                                    className={classes.linkWhy}>&nbsp;&nbsp;&nbsp;Why?</a>}
                    </Typography>
                    {!loadedData!.error ? checkBoxControl : undefined}
                </DialogContent>
                <DialogActions>
                    <AppButton color="info" onClick={() => this.props.handleClose()}>Cancel</AppButton>
                    <AppButton color="secondary" disabled={(processedData && !processedData.error) || toImport === 0}
                        onClick={this.importData}>{submitLabel} {toImport > 0 ? Utils.withS(toImport, 'golfer') : ''}</AppButton>
                </DialogActions>
            </XSMobileDialog>
            <FirebaseDataComponent query={Backend.rosterDb(workingUserId)} onMap={this.onContactDetails} />
            <FirebaseDataComponent query={Backend.golferDb(event.id)} onMap={this.onGolfers} />
            <FirebaseDataComponent query={Backend.query(Backend.competitionsDb(event.id), Backend.orderBy('order'))}
                onData={this.onCompetitions} />
        </>;
    }
}

function ImportReviewDialogFC(props: Omit<Props, 'classes' | 'workingUserId'>) {
    const classes = useAppStyles();
    const userAware = useUserAware();
    return userAware.workingUserId ? <ImportReviewDialog classes={classes} workingUserId={userAware.workingUserId}  {...props} /> : null;
}

export default ImportReviewDialogFC;
